import { Button, Typography, StickyAppFooter } from "@aiops/styleguide";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { paths } from "../../shared/constants";
import { useSelector, useDispatch } from "react-redux";
import { Group } from "../../shared/models/group";
import { fetchGroupUsers, updateGroupName } from "./utils";
import GroupDetailsTable from "../GroupDetailsTable";
import { pagination } from "../../shared/constants";
import { User } from "../../shared/models/user";
import "./GroupDetailsPage.scss"

type GroupDetailsPageProps = {
  basePath: string
}

const GroupDetailsPage = ({ basePath }: GroupDetailsPageProps) => {
  // Handles table loading state
  const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
  // String display name of selected group
  const [selectedGroupName, setSelectedGroupName] = useState<string>();
  // Array of user objs of users in selected group
  const [usersInGroup, setUsersInGroup] = useState<User[]>();

  // Zero-indexed number of the page you're currently on.
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Total count of users in group
  const [displayableRowCount, setDisplayableRowCount] = useState<number>(0);

  // Number of rows to display per page.
  const [rowsPerPage, setRowsPerPage] = useState<number>(pagination.ROWS_PER_PAGE_DEFAULT);

  // setTable is loading and fetch group users 
  const refreshTable = () => {
    setTableIsLoading(true)
    fetchGroupUsers(groupList, id, dispatch,
      setSelectedGroupName, setUsersInGroup, setTableIsLoading,
      setDisplayableRowCount, rowsPerPage, currentPage * rowsPerPage)
  };

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { groupList } = useSelector((state: {
    groupList: Group[],
  }) => state);


  useEffect(() => {
    refreshTable();
  }, [rowsPerPage, currentPage])

  useEffect(() => {
    setTableIsLoading(true)
    updateGroupName(id, setSelectedGroupName, groupList)
  }, [id])

  return (
    <div className="col">
      <div className="group-details-table">
        <div className="heading-row">
          <Typography variant="heading2-titles">
            {selectedGroupName}
          </Typography>
        </div>
        <GroupDetailsTable
          groupId={id}
          isLoadingData={tableIsLoading}
          users={usersInGroup}
          basePath={basePath}
          displayableRowCount={displayableRowCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          refreshTable={refreshTable}
        />
      </div>
      <StickyAppFooter>
        <Button
          variant="outlined"
          onClick={() => navigateToUrl(`/${basePath}/${paths.GROUP_MGMT}`)}
          data-testid="back-button">
          Back
        </Button>
      </StickyAppFooter>
    </div>
  )
}

export default GroupDetailsPage;