import * as types from "../types";

export const totalGroupCountReducer = (
  totalUserCount = 0,
  action = { type: types.TOTAL_GROUP_COUNT_SET, payload: 0 },
) => {
  switch (action.type) {
    case (types.TOTAL_GROUP_COUNT_SET):
      return action.payload;
    default:
      return totalUserCount;
  }
}
