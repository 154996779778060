import "./UserManagementFooter.scss";
import { useNavigate } from "react-router-dom";
import {
  useModal,
  useToast,
  Button,
  StickyAppFooter,
} from "@aiops/styleguide";
import CreateUserModal from "../CreateUserModal/CreateUserModal";

/**
 * Footer across the bottom of the user management screen.
 */
const UserManagmentFooter = () => {

  const navigate = useNavigate();

  const { openModal } = useModal();
  const { setCurrentToast } = useToast();

  const openCreateUserModal = () => {
    openModal({
      headlineText: "Create New User",
      children: <CreateUserModal setToast={setCurrentToast} />
    });
  }

  return (
    <StickyAppFooter >
      <Button
        variant="outlined"
        onClick={() => navigate('/')}
        data-testid="back-button"
      >
        Back
      </Button>
      <Button
        variant="filled"
        onClick={openCreateUserModal}
        data-testid="create-user-button"
      >
        Create User
      </Button>
    </StickyAppFooter >
  );
};

export default UserManagmentFooter;
