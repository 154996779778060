import {
  AppWrapper,
  AuthProvider,
  ModalProvider,
  ToastProvider,
  ErrorPage,
} from "@aiops/styleguide";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import UserManagementPage from "./components/UserManagementPage";
import GroupManagementPage from "./components/GroupManagementPage";
import { Provider } from "react-redux";
import store from './shared/store';
import SettingsMenu from "./components/SettingsMenu";
import UserDetailsPage from "./components/UserDetailsPage";
import { paths } from './shared/constants';
import './globalSettingsStyles.scss';
import GroupDetailsPage from "./components/GroupDetailsPage";
import { GenAISettings } from "@aiops/chatbot";

export default function Root(props) {

  return (
    <AppWrapper>
      <AuthProvider
        // All users have access to the settings app regardless of permissions
        checkPermissions={() => true}
      >
        <Provider store={store}>
          <ModalProvider>
            <ToastProvider>
              <Router basename={`/${props.app.path}`}>
                <Routes>
                  <Route
                    path={'/'}
                    element={<SettingsMenu />}
                  />
                  <Route
                    path={`/${paths.USER_MGMT}`}
                    element={<UserManagementPage basePath={props.app.path} />}
                  />
                  <Route
                    path={`/${paths.USER_MGMT}/:id`}
                    element={<UserDetailsPage basePath={props.app.path} />}
                  />
                  <Route
                    path={`/${paths.GROUP_MGMT}`}
                    element={<GroupManagementPage basePath={props.app.path} />}
                  />
                  <Route
                    path={`/${paths.GROUP_MGMT}/:id`}
                    element={<GroupDetailsPage basePath={props.app.path} />}
                  />
                  <Route
                    path={`/${paths.GEN_AI}/*`}
                    // @ts-ignore
                    element={<GenAISettings basePath={`${props.app.path}/${paths.GEN_AI}`} />}
                  />
                  <Route
                    path={'*'}
                    element={<ErrorPage
                      title="Error: 404 Not Found"
                      message={"This page does not exist."}
                    />}
                  />
                </Routes>
              </Router>
            </ToastProvider>
          </ModalProvider>
        </Provider>
      </AuthProvider>
    </AppWrapper >
  )
}
