import { ToastMsg, Typography } from "@aiops/styleguide";
import trashIcon from '../../assets/trashIcon.svg';
import { User } from "../../shared/models/user";
import { userStates } from "../../shared/constants";

/**
 * Returns a className for a user status cell based on the user's status.
 * 
 * @param status 
 * Status string.
 */
export const getStatusClassName = (status: string) => {
    switch (status) {
        case 'Active':
            return 'active-color';
        case 'Pending':
            return 'pending-color';
        case 'Deactivated':
            return 'deactivated-color';
        default:
            return '';
    }
}

/**
 * Returns a Typography element, of the specified variant with any specified
 * classNames, wrapped around the provided text.
 * 
 * @param text 
 * The text to wrap in a Typography element.
 * 
 * @param variant 
 * The Typography variant.
 * 
 * @param className 
 * String that is a whitespace-separated list of classNames to apply to the 
 * Typography element.
 * 
 */
export const typographyFromVariant = (text: string, variant: any, className = "") => (
    <Typography
        variant={variant}
        className={className}
    >
        {text}
    </Typography>
)

/**
 * Returns a table cell with edit and delete buttons for that user.
 * 
 * @param user 
 * User object for the user in this row.
 * 
 * @param confirmDeleteUsers 
 * Function that takes an array with just the user's id in it and fires when
 * the edit button is pressed.
 * 
 * This function takes an array of ids (in this case just one id) so that the
 * function signature can be the same whether the user is deleting one row or
 * many - it always takes an array of one or more IDs.
 * 
 */
export const actionsCellFromUser = (
    user: Record<string, any>,
    confirmDeleteUsers: (userName: string[]) => void,
) => {
    return (
        <div className="actions-cell-wrap">
            <button
                onClick={() => confirmDeleteUsers([user.userName])}
            >
                <img src={trashIcon} alt={"delete user"} />
            </button>
        </div>
    )
}

/**
 * Returns the trash button for batch deleting users.
 * 
 * @param confirmBatchDelete 
 * Function that takes no parameters and opens the delete users confirmation
 * modal.
 * 
 * @param isDisabled 
 * When true, the button is disabled.
 * 
 */
export const trashButton = (confirmBatchDelete: () => void, isDisabled: boolean) => (
    <button
        className="icon-btn trash"
        onClick={confirmBatchDelete}
        disabled={isDisabled}
        style={{
            cursor: isDisabled ? 'auto' : 'pointer',
        }}
    >
        <img
            src={trashIcon}
            alt={"Delete"}
            className="hibiscus-fill"
        />
    </button>
)

/**
 * Returns the 'User Management' title and a placeholder space for a search bar.
 */
export const titleAndSearchRow = () => (
    <div className="row justified-row title-row">
        <Typography
            variant="heading2-titles"
        >
            User Management
        </Typography>
        {/* <div>
        Search Bar TBD
      </div> */}
    </div>
);

/**
 * Updates the filter names and counts.
 * 
 * @param filters 
 * Array of filter objects that will be mutated.
 * 
 * @param activeCount 
 * Count of active users.
 * 
 * @param pendingCount 
 * Count of pending users.
 * 
 * @param deactivatedCount 
 * Count of deactivated users.
 * 
 */
export const updateFilterNames = (
    filters: any[],
    activeCount: number,
    pendingCount: number,
    deactivatedCount: number,
) => {
    filters.forEach(filter => {
        switch (filter.id) {
            case userStates.PENDING:
                filter.text = `Pending`;
                filter.count = pendingCount;
                break;
            case userStates.ACTIVE:
                filter.text = `Active`;
                filter.count = activeCount;
                break;
            case userStates.INACTIVE:
                filter.text = `Deactivated`;
                filter.count = deactivatedCount;
                break;
            default:
                break;
        }
    })
}

/**
 * Returns the user's name as a button.
 * 
 * @param user 
 * User object for the user in this row.
 * 
 * @param onClick 
 * Function that fires when the button is clicked.
 * 
 */
export const clickableUserName = (user: User, onClick: () => void) => (
    <button
        className="user-link-button"
        onClick={onClick}
    >
        {user.fullName}
    </button>
)

/**
 * Returns toast message based off of response and error flag
 * @param isError 
 * Boolean response from api call indicating if error occurred
 * @returns formatted toast object of type ToastMsg
 */
export const getToast = (isError) => {
    return (isError
        ? {
            severity: 'error',
            message: 'User Update Failed',
        }
        : {
            severity: 'success',
            message: 'User Successfully Updated'
        }) as ToastMsg
};
