import { Table, CircularProgress } from "@aiops/styleguide";
import "./GroupDetailsTable.scss";
import {
  getRows,
  getTableSelectability,
  TableTopActions,
  TitleRow,
} from "./utils";
import { useEffect, useState } from "react";
import { getTablePagination } from "./utils";
import { columns } from "./config";
import { User } from '../../shared/models/user';

type GroupDetailsTableProps = {
  users: User[],
  basePath: string,
  isLoadingData: boolean,
  displayableRowCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  rowsPerPage: number
  setRowsPerPage,
  groupId: string,
  refreshTable: () => void,
}

const GroupDetailsTable = (
  { users,
    basePath,
    isLoadingData,
    displayableRowCount,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    groupId,
    refreshTable,
  }: GroupDetailsTableProps) => {

  // Array of IDs of users in selected rows.
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  // Whenever the users list changes, make sure that all selected user ids are
  // in that list. Without this check the table's select all checkbox can remain
  // indeterminate after a user is deleted when using the checkboxes.
  useEffect(() => {
    setSelectedUserIds([...selectedUserIds].filter((selectedUserId) => users.some(u => u.id === selectedUserId)))
  }, [users]);

  // Table pagination object state
  const [tablePagination, setTablePagination] = useState(
    getTablePagination({
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      displayableRowCount: displayableRowCount || 0,
      setCurrentPage: setCurrentPage,
      setRowsPerPage: setRowsPerPage
    })
  )

  useEffect(() => {
    const paginationObj = getTablePagination({
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      displayableRowCount: displayableRowCount,
      setCurrentPage: setCurrentPage,
      setRowsPerPage: setRowsPerPage
    })

    setTablePagination(paginationObj);
  }, [currentPage, rowsPerPage, users, displayableRowCount])


  if (isLoadingData) return <CircularProgress />

  const headerCells: Record<string, any> = {};
  // maps columns into table header cell object
  columns.forEach((col) => {
    const field = col.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: col.title,
      isCurrentSortColumn: false,
    }
  });

  // maps columns into table header cell object
  columns.forEach((col) => {
    const field = col.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: col.title,
      isCurrentSortColumn: false,
    }
  });

  return (
    <div className="group-card">
      <div className="col group-table">
        <TitleRow
          basePath={basePath}
          usersInGroup={users}
          groupId={groupId}
          refreshTable={refreshTable}
        />
        <TableTopActions
          selectedUserIds={selectedUserIds || []}
          users={users || []}
          groupId={groupId}
          refreshTable={refreshTable}
        />
        <Table
          selectability={getTableSelectability(selectedUserIds, users, setSelectedUserIds)}
          tableIsLoading={isLoadingData}
          columnKeys={columns.map((col) => col.field)}
          headerCells={headerCells}
          rows={getRows(users, basePath, groupId, refreshTable)}
          pagination={tablePagination}
        />
      </div>
    </div>
  )
}
export default GroupDetailsTable;