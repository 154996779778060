export const paths = {
    SETTINGS_APP: 'settings',
    USER_MGMT: 'users',
    GROUP_MGMT: 'groups',
    GEN_AI: 'gen-ai',
}

export const pagination = {
    ROWS_PER_PAGE_OPTIONS: [25, 50, 100],
    ROWS_PER_PAGE_DEFAULT: 50,
}

export const userStates = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}

export const endpoints = {
    PREFIX: '/aiopsd/security',
    GET_ALL_USERS: '/getAllUsers',
    ADD_USER: '/addUser',
    UPDATE_USER: '/user',
    GET_ALL_GROUPS_FOR_USER: '/getAllGroupsForUser',
    GET_ALL_GROUPS: '/getAllGroups',
    GET_ALL_USERS_IN_GROUP: '/getAllUsersInGroup',
    ASSIGN_USER_TO_GROUP: '/assignUserToGroup',
    REMOVE_USER_FROM_GROUP: '/removeUserGroupMapping',
}
