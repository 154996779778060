import {
  Typography,
  Button,
  IconButton,
  useModal,
  useToast,
} from "@aiops/styleguide";
import { navigateToUrl } from "single-spa";
import { paths } from "../../shared/constants";
import linkIcon from "../../assets/linkIcon.svg";
import trashIcon from "../../assets/trashIcon.svg";
import "./GroupDetailsTable.scss";
import { User } from "../../shared/models/user";
import { pagination } from "../../shared/constants";
import AddUserToGroupModal from "../AddUserToGroupModal";
import RemoveUserFromGroupModal from "../RemoveUserFromGroupModal"

// Props for TitleRow component
type TitleRowProps = {
  basePath: string,
  usersInGroup: User[],
  groupId: string,
  refreshTable: () => void,
}
/**
 * @param basePath 
 * Path to base settings route
 * 
 * @param usersInGroup
 * Array of users in selected group
 * 
 * @param groupId
 * Id of group being displayed
 * 
 * @param refreshTable 
 * Function that forces refetch/rerender of the groupDetailsTable page
 * 
 * @returns Row with card title and link to UserManagementPage
 */
export const TitleRow = ({ basePath, usersInGroup, groupId, refreshTable }: TitleRowProps) => {

  const { openModal } = useModal();
  const { setCurrentToast } = useToast();

  const openAddUserModal = () => {
    openModal({
      headlineText: "Add New User",
      children: <AddUserToGroupModal
        setToast={setCurrentToast}
        openModal={openModal}
        usersInGroup={usersInGroup}
        groupId={groupId}
        refreshTable={refreshTable}
      />,
    });
  }

  return (
    <div className="title-row">
      <Typography
        className="section-text"
        variant="heading6-bold"
      >
        Manage Related Users
      </Typography>
      <div className="button-group">
        <Button
          variant="outlined"
          data-testid="title-row-button"
          onClick={() => navigateToUrl(`/${basePath}/${paths.USER_MGMT}`)}
        >
          <div className="button-group">
            User Management
            <img
              src={linkIcon}
              alt={"Link"}
            />
          </div>
        </Button>
        <Button
          variant="filled"
          onClick={openAddUserModal}
        >
          Add User
        </Button>
      </div>
    </div>
  )
}

// Props for UserLink Component
type UserLinkProps = {
  user: User,
  basePath: string
}
/**
 * 
 * @param user
 * user object of type User
 * 
 * @param basePath 
 * Path to base settings route
 * 
 * @returns clickable link that naviagtes you to UserDetailsPage for clicked user
 */
export const UserLink = ({ user, basePath }: UserLinkProps) => {
  const link = `/${basePath}/${paths.USER_MGMT}/${user.id}`
  return (
    <button
      className="user-link-button"
      onClick={() => navigateToUrl(link)}
      data-testid={`user-link-${user.id}`}
    >
      {user.fullName}
    </button>
  )
}

export const confirmRemoveUsers = (users: User | User[], groupId: string, refreshTable: () => void) => {
  const { openModal, closeModal, blockCloseModal } = useModal();
  const { setCurrentToast } = useToast();

  return () => openModal({
    children: (
      <RemoveUserFromGroupModal
        users={Array.isArray(users) ? users : [users]}
        groupId={groupId}
        closeModal={closeModal}
        blockCloseModal={blockCloseModal}
        openModal={openModal}
        refreshTable={refreshTable}
        setToast={setCurrentToast}
      />
    ),
  });
}

// Props for RemoveUserButton
type RemoveUserButtonProps = {
  user: User,
  groupId: string,
  refreshTable: () => void,
}
/**
 * Returns a trash icon button that opens a modal to remove a user from a group. 
 * 
 * @param user 
 * user object of type User
 * 
 * @param groupId
 * Id of group from which to remove user
 * 
 * @param refreshTable
 * Function to call after user is successfully removed from group
 * 
 */
export const RemoveUserButton = ({ user, groupId, refreshTable }: RemoveUserButtonProps) => {
  return (
    <button
      className="icon-btn trash"
      data-testid={`remove-user-${user.id}`}
      onClick={confirmRemoveUsers(user, groupId, refreshTable)}
    >
      <img src={trashIcon} alt={"remove user"} />
    </button >
  )
}

/** Function to create rows object to pass to styleguide Table
 * 
 * @param users 
 * Array of users of type User
 * 
 * @param basePath 
 * Path to base settings route
 * 
 * @param groupId
 * Id of group being displayed
 * 
 * @param forceRerender
 * Function that forces refetch/rerender of the groupDetailsTable page
 * 
 * @returns row object that's passed to styleguide table
 */
export const getRows = (
  users: User[] | undefined,
  basePath: string,
  groupId: string,
  refreshTable: () => void,
) => {
  if (!users) return []

  return users.map(user => {
    return ({
      ...user,
      fullName: <UserLink
        key={user.id}
        user={user}
        basePath={basePath}
      />,
      actions: <RemoveUserButton
        user={user}
        groupId={groupId}
        refreshTable={refreshTable}
      />
    })
  })
}


/** Object that defines Table selectability
 * 
 * @param selectedUserIds 
 * Array of currently selected userIds
 * 
 * @param userList 
 * Array of all users of type User
 * 
 * @param setSelectedUserIds 
 * State updating function for selectedUserIds state
 * 
 * @returns tableSelectability object that's passed to styleguide Table
 */
export const getTableSelectability = (
  selectedUserIds: string[],
  userList: User[],
  setSelectedUserIds: (ids: string[]) => void
) => {
  return (
    {
      rowIsSelectable: () => true,
      rowIsSelected: (user) => selectedUserIds?.includes(user?.id),
      selectAllCheckbox: true,
      selectAllCheckboxDisabled: userList?.length === 0,
      selectAllCheckboxValue: selectedUserIds?.length === userList?.length,
      selectAllCheckboxIndeterminate: selectedUserIds?.length > 0 && selectedUserIds?.length < userList.length,
      onSelectAllToggle: (val) => {
        if (val) {
          setSelectedUserIds(userList?.map((user) => user.id));
        } else {
          setSelectedUserIds([]);
        }
      },
      onSelectRowToggle: (val, user) => {
        if (val) {
          setSelectedUserIds([
            ...selectedUserIds,
            user?.id,
          ]);
        } else {
          setSelectedUserIds([...selectedUserIds].filter((clickedUsername) => clickedUsername !== user?.id));
        }
      }
    })
}

/**
 * 
 * @param currentPage 
 * @param rowsPerPage 
 * @param users 
 * @param displayableRowCount 
 * @param setCurrentPage 
 * @param setRowsPerPage 
 * @returns Returns table pagination object
 * TODO This should be added as a styleguide function to avoid duplication
 */
export const getTablePagination = (
  { currentPage,
    rowsPerPage,
    displayableRowCount,
    setCurrentPage,
    setRowsPerPage }
) => {
  return ({
    currentPage,
    rowsPerPage,
    rowsPerPageOptions: pagination.ROWS_PER_PAGE_OPTIONS,
    totalResults: displayableRowCount,
    onNextPage: () => {
      const maxPageNum = Math.ceil(displayableRowCount / rowsPerPage) - 1;
      setCurrentPage(Math.min(currentPage + 1, maxPageNum));
    },
    onPreviousPage: () => setCurrentPage(Math.max(0, currentPage - 1)),
    onSelectRowsPerPage: (r) => {
      // When changing the number of results per page, dump the user back to the
      // first page.
      setCurrentPage(0);
      setRowsPerPage(r)
    },
  })
}

// Props for TableTopActions component
type TableTopActionsProps = {
  selectedUserIds: string[],
  users: User[],
  groupId: string,
  refreshTable: () => void,
}

/**
 * Renders bulk delete button above GroupDetailsTable.
 * 
 * @param selectedUserIds
 * Array of IDs of selected users.
 * 
 * @param users
 * Array of all users (selected or not).
 * 
 * @param groupId
 * Id for group from which to remove users.
 * 
 * @param refreshTable
 * Function that forces refetch/rerender of the groupDetailsTable page.
 * 
*/
export const TableTopActions = ({
  selectedUserIds,
  users,
  groupId,
  refreshTable,
}: TableTopActionsProps) => {

  const userIdsAsObj = {};
  selectedUserIds.forEach((id) => userIdsAsObj[id] = true);
  const selectedUsers = users.filter((user) => userIdsAsObj[user.id]);

  return (
    <div className="table-actions-row">
      <IconButton
        className="bulk-delete-button"
        disabled={selectedUsers.length === 0}
        onClick={confirmRemoveUsers(selectedUsers, groupId, refreshTable)}
        data-testid="bulk-delete-btn"
      >
        <img src={trashIcon} alt={"bulk remove user"} />
      </IconButton>
    </div>
  )
}
