import * as React from "react";
import { Typography } from "@aiops/styleguide";

/**
 * Type for props of UserDetailsSection component.
 * 
 * @property {string} title
 * The title of the section.
 * 
 * @property {ReactElement | ReactElement[]} content
 * The content of the section.
 * 
 * @property {ReactElement} titleRowRight
 * The content to display on the right side of the title row, if any.
 * 
 */
export type UserDetailsSectionProps = {
  title: string,
  content: React.ReactElement | React.ReactElement[],
  titleRowRight?: React.ReactElement,
  onClick?: () => void,
};

/**
 *  A section of the user details page with a title and content.
 */
const UserDetailsSection = ({ title, content, titleRowRight, }: UserDetailsSectionProps) => {
  return (
    <div className="user-details-section">
      <div className="row">
        <Typography variant="heading7">
          {title}
        </Typography>
        {titleRowRight}
      </div>
      <div className="content">
        {content}
      </div>
    </div>
  );
};

export default UserDetailsSection;
