import * as types from "../types";

export const deactivatedUserCountReducer = (
    deactivatedUserCount = 0,
    action = { type: types.DEACTIVATED_USER_COUNT_SET, payload: 0 },
) => {
    switch (action.type) {
        case (types.DEACTIVATED_USER_COUNT_SET):
            return action.payload;
        default:
            return deactivatedUserCount;
    }
}
