import { Typography, Button, useAuth } from "@aiops/styleguide";
import { getStatusClassName } from "../UserManagementPage/util";

/**
 * Type for props of UserDetailsHeadingRow component.
 * 
 * @param user
 * User object for the user whose details are being shown.
 * 
 * @param editMode
 * Boolean that represents whether the user is in edit mode or not.
 * 
 * @param setEditMode
 * Function that sets edit mode to true or false.
 * 
 */
export type UserDetailsHeadingRowProps = {
  user: Record<string, any>,
  editMode: boolean,
  setEditMode: (arg: boolean) => void,
};

/**
 * Renders a row at the top of the user details page with the user's name,
 * dot with a color that represents their status, and a button to open edit mode
 * to edit the user.
 */
const UserDetailsHeadingRow = ({ user, editMode, setEditMode }: UserDetailsHeadingRowProps) => {

  return (
    <div className="heading-row">
      <div className="row">
        <Typography
          variant="heading2-titles"
          data-testid="user-full-name"
        >
          {user?.fullName}
        </Typography>
        <div className={`status-dot ${getStatusClassName(user?.statusString)}-bg`} />
      </div>
      {!editMode &&
        <Button
          variant="filled"
          onClick={() => setEditMode(true)}
          data-testid="edit-user-button"
        >
          Edit User
        </Button>
      }
    </div>
  );
};

export default UserDetailsHeadingRow;
