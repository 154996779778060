import { Typography, Button, FormTextfield, FormRadioButtonGroup } from "@aiops/styleguide";
import { getStatusClassName } from "../UserManagementPage/util";
import UserDetailsAppGroups from "../UserDetailsAppGroups";
import { paths } from '../../shared/constants';
import React from "react";
import { UserDetailsSectionProps } from "../UserDetailsSection";
import nothingFoundIcon from '../../assets/nothingFoundIcon.svg';
import { navigateToUrl } from "single-spa";
import linkIcon from '../../assets/linkIcon.svg';
import "./UserDetailsBody.scss"

// TODO: Refactor this for use with edit mode.
export const titleValuePair = (title: string, value: string): React.ReactElement => {
  return (
    <div className="col title-value-pair">
      <Typography variant="paragraph1-bold">
        {title}
      </Typography>
      <Typography variant="paragraph1">
        {value}
      </Typography>
    </div>
  )
}

/**
 * Returns a UserDetailsSectionProps object for the user status section.
 * 
 * @param user 
 * User object for the user whose details are being displayed.
 * 
 * @param editMode 
 * Boolean that is true when the user is editing the user details.
 * 
 * @param control
 * React hook form control object that manages form state
 */
export const userStatusSection = (
  user: Record<string, any>,
  editMode: boolean,
  control: any
): UserDetailsSectionProps => {

  const getRadioGroup = () => {
    if (user?.statusEnum === "PENDING") return [{ value: "PENDING", label: "Pending" }];
    return [{ value: 'ACTIVE', label: 'Active' }, { value: 'INACTIVE', label: 'Inactive' }];
  }

  // What to display when not in edit mode
  const displayContent = (
    <Typography
      variant="paragraph1-bold"
      className={getStatusClassName(user?.statusString || "Unknown")}>
      {user?.statusString || "Unknown"}
    </Typography>
  )

  // What to display when in edit mode
  const editContent = (
    <div className="title-value-pair-wrap">
      <FormRadioButtonGroup
        control={control}
        controlLabel='status'
        groupData={getRadioGroup()}
      />
    </div>
  )

  return {
    title: "User Status",
    content: editMode ? editContent : displayContent,
  }
}

/**
 * Returns a UserDetailsSectionProps object for the personal data section.
 * 
 * @param user 
 * User object for the user whose details are being displayed.
 * 
 * @param editMode 
 * Boolean that is true when the user is editing the user details.
 *  @param control
 * React hook form control object that manages form state
 */
export const personalDataSection = (
  user: Record<string, any>,
  editMode: boolean,
  control: any
): UserDetailsSectionProps => {
  const fieldWidth = 350;
  // What to display when not in edit mode
  const displayContent = <div className="title-value-pair-wrap">
    <div className="row">
      {titleValuePair("Given Name", user?.firstName || "")}
      {titleValuePair("Family Name", user?.lastName || "")}
    </div>
    <div className="row">
      {titleValuePair("Email", user?.email || "")}
    </div>
  </div>

  // What to display when in edit mode
  const editContent = <div className="title-value-pair-wrap">
    <div className="row form-group">
      <FormTextfield
        controlLabel='givenName'
        control={control}
        width={fieldWidth}
        size='small'
        label="Given Name"
        placeholder=''
      />
      <FormTextfield
        controlLabel='familyName'
        control={control}
        width={fieldWidth}
        size='small'
        label="Family Name"
        placeholder=''
      />
    </div>
    <div className="row">
      {titleValuePair("Email", user?.email || "")}
    </div>
  </div>

  return {
    title: "Personal Data",
    content: editMode ? editContent : displayContent,
  }
}

/**
 * Returns a UserDetailsSectionProps object for the user's groups section, or
 * null if groups param is null.
 * 
 * @param groups 
 * Array of objects where each key is the name of a microsolution and each value
 * is an array of group objects that belong to that microsolution. Can be null;
 * if so the function will return null. This is because this data is fetched
 * with a separate API call and therefore isn't available when the rest of the
 * list of sections is being generated.
 * 
 * @param basePath
 * String of base path of settings app pulled from root.component props
 */
export const groupsSection = (
  groups: Record<string, any>[] | null | undefined,
  basePath: string
): UserDetailsSectionProps | null => {

  // If groups is null, display nothing - either the API call to get the groups
  // is in progress or it errored. If groups is an empty object, display the
  // zero groups content (see below).
  if (!groups) {
    return null;
  }

  const pathToGroupMgmt = `/${basePath}/${paths.GROUP_MGMT}`;

  return {
    title: "Microsolutions and Groups",
    titleRowRight: <Button
      variant="outlined"
      onClick={() => navigateToUrl(pathToGroupMgmt)}
      data-testid="group-management-button"
    >
      <div className="group-mgmt-button-group">
        Group Management
        <img
          src={linkIcon}
          alt={"Link"}
        />
      </div>
    </Button>,
    content: <>
      {groups.length === 0
        ? zeroGroupsContent()
        : groups.map((g, idx) => (
          <UserDetailsAppGroups
            key={idx}
            appName={Object.keys(g)[0]}
            groups={Object.values(g)[0]}
            basePath={basePath}
          />
        ))}
    </>
  }
}

/**
 * Returns what to display when there are no groups.
 */
export const zeroGroupsContent = () => {
  return (
    <div className="col zero-groups">
      <img src={nothingFoundIcon} alt="Nothing found" />
      <Typography variant="heading4-light">
        No groups available at the moment
      </Typography>
      <Typography variant="paragraph1">
        Assign groups via group management.
      </Typography>
    </div>
  )
}

/**
 * Returns a UserDetailsSectionProps object for the delete user section.
 */
export const deleteUserSection = ({onClick}): UserDetailsSectionProps => ({
  title: "Delete User",
  content: (
    <Button
      variant="filled"
      onClick={onClick}
      data-testid="delete-user-button"
    >
      Delete User
    </Button>
  ),
});
