import { ToastMsg } from '@aiops/styleguide';
import { FieldValues } from 'react-hook-form';
import { editUser, getGroupsGroupedByApp, getSingleUser, getUsersGroups } from '../../shared/apiHelper';
import { User } from '../../shared/models/user';
import { setErrorProps, setUserProps, setToastProps, resetFormProps, updateUserDetailsProps } from './models';

/**
 * Fetches data for one user and updates user state
 * 
 * @param id Id of user to fetch data for
 * @param setUser State updating function for user data
 * @param setError State updating function for errors
 * 
 */
export const fetchSingleUser = (
  id: string,
  setUser: setUserProps,
  setError: setErrorProps,
) => {
  getSingleUser(id)
    .then((res) => {
      setUser(res);
    }).catch((e) => {
      console.error("Error getting user: ", e);
      setError(`Unable to get user with id "${id}".`);
    });
}

/** Fetches, restructures the format of, and sets user's groups.
 * @param id Unqiue user id
 * @param setGroups Group state updating function
 * @param setCurrentToast Toast updating hook
*/
export const getAndSetUserGroups = (
  id: string,
  setGroups: (res) => void,
  setCurrentToast: setToastProps
) => {
  getUsersGroups(id)
    .then((res) => {
      setGroups(getGroupsGroupedByApp(res));
    }).catch((e) => {
      console.error("Error getting groups: ", e);
      setCurrentToast({
        message: `Unable to get user's groups. Please contact your system administrator.`,
        severity: "error",
      });
    });
}
/** Function to reset edit user form to default values
 * 
 * @param reset React hook form reset function, sets default form state to provided values
 * @param user User data for selected user
 */
export const resetForm = (
  reset: resetFormProps,
  user: User
) => {
  reset({
    givenName: user?.firstName,
    familyName: user?.lastName,
    status: user?.statusEnum
  })
}

/** Function to update user data
 * 
 * @param data Form data with current values for all form fields
 * @param setIsLoading State updating function
 * @param user User object with information of selected user
 * @param dirtyFields Form fields that have been updated
 * @param getToast Function that returns toast message based on error state
 * @param setEditMode State updating function for editMode boolean
 * @param setCurrentToast Toast upadting function
 * @param reset React hook form reset function, sets default form state to provided values
 * @param id Unique user identifier
 * @param setUser User state updating function
 * @param setError Error state updating function
 * 
 */

export const updateUserDetails = async (props: updateUserDetailsProps) => {
  const { data, setIsLoading, user, dirtyFields, getToast,
    setEditMode, setCurrentToast, reset, id, setUser, setError } = props
  let apiError = false;
  setIsLoading(true)
  if (!user?.email) return
  try {
    await editUser(user.email,
      dirtyFields, data
    )
  } catch (err) {
    apiError = true;
  }
  // Generate toast message
  const toast: ToastMsg = getToast(apiError);
  if (!apiError) {
    // Refetches updated user data to show updated values
    fetchSingleUser(id, setUser, setError)
    // set edit mode to false
    setEditMode(false)
    resetForm(reset, user)
  }
  setCurrentToast(toast)
  setIsLoading(false)
}