import "./SettingsMenu.scss";
import { Typography } from "@aiops/styleguide";
import SettingsCard from "../SettingsCard/SettingsCard";
import { settingsConfig } from "./config";

/**
 * Renders the menu with the settings heading and cards for each settings page.
 */
const SettingsMenu = () => {
  return (
    <div className="col settings-menu">
      <div className="col title-and-cards">
        <Typography
          variant="heading2-titles"
        >
          Settings
        </Typography>
        <div className="row setting-card-row" >
          {settingsConfig.map((config, i) => {
            return (
              <SettingsCard
                key={`${config.title}-${i}`}
                title={config.title}
                path={config.path}
                icon={config.icon}
                iconAltText={config.iconAltText}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
