import { FormTextfield } from "@aiops/styleguide";
import { ICreateUser } from './CreateUserModal';

// 376px is from the Figma mocks
export const TEXT_FIELD_WIDTH = 376;

// Toast to display if create user api call is successful
export const successToast = () => ({
    severity: 'success' as const,
    message: "User was created.",
});

// Returns a Toast to display when the create user api call fails
// Checks whether message is actually a string so that you can pass it what
// you think will be a string (like error.message) and it will fail gracefully
// if you're wrong.
export const errorToast = (message: string = "") => ({
    severity: 'error' as const,
    message: (message && typeof message === 'string') ? message : "Unable to create user.",
});

// Returns a FormTextField component. This is a function because the same
// field with almost the same props appears three times.
export const getFormTextField = (
    control: any,
    controlLabel: string,
    placeholder: string,
    key: string | number,
) => (
    <FormTextfield
        key={key}
        control={control}
        controlLabel={controlLabel}
        placeholder={placeholder}
        textfieldProps={{
            sx: {
                margin: '5px',
                width: `${TEXT_FIELD_WIDTH}px`,
            }
        }}
    />
);

/**
 * Returns true if givenName, familyName, and email are all non-empty strings
 * with more than just white space, and email matches a simple email regex.
 * 
 * @param formData 
 * The data from the react-hook-form control/handleSubmit.
 */
export const dataIsValid = (formData: ICreateUser): boolean => {
    // Trim to turn strings with just white space into empty strings
    const givenName = formData.givenName?.trim();
    const familyName = formData.familyName?.trim();
    const email = formData.email?.trim();

    // All fields must be non-empty strings
    if (!(givenName && familyName && email)) {
        return false;
    }

    const basicEmailRegex = /^[a-zA-Z0-9._-]{1,64}\@[a-zA-Z0-9.-]{1,64}\.[a-zA-Z]{2,4}$/

    // Returns true if email matches email regex
    return basicEmailRegex.test(email);
}