import * as types from "../types";

export const activeUserCountReducer = (
    activeUserCount = 0,
    action = { type: types.ACTIVE_USER_COUNT_SET, payload: 0 },
) => {
    switch (action.type) {
        case (types.ACTIVE_USER_COUNT_SET):
            return action.payload;
        default:
            return activeUserCount;
    }
}
