import { User, userFromApiRes } from "../models/user";
import { endpoints } from "../constants";
import {
    authenticatedFetchRequest,
    getFullUrlFromEndpoint,
    appendParamsToString,
    createFiltersString,
    setUserCountsFromRawApiReponse,
    convertUserData,
} from './utils';

/**
 * Returns the list of users the currently logged-in user has permission to
 * manage or see.
 * 
 * @param filters 
 * String array of filters to apply to the result. Each string should be one of
 * the user states (ie 'ACTIVE', 'PENDING', 'DEACTIVATED').
 * 
 * @param limit 
 * The number of results to fetch.
 * 
 * @param offset 
 * The index of the first result to fetch.
 */
export const getUsers = async (
    filters: string[],
    limit: number = 100,
    offset: number = 0
): Promise<User[]> => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.GET_ALL_USERS);
    const params = {
        filter: createFiltersString(filters, "state"),
        limit: limit.toString(),
        offset: offset.toString(),
    }
    const urlWithParams = appendParamsToString(fullUrl, params)
    const rawRes = await authenticatedFetchRequest(urlWithParams, "GET");

    if (!rawRes?.users || !Array.isArray(rawRes?.users)) {
        throw new Error(`Expected array but got: ${rawRes?.users}`);
    }

    setUserCountsFromRawApiReponse(rawRes);
    return rawRes.users.map((obj: any) => userFromApiRes(obj))
}

/**
 * Fetches and returns a user by userId.
 * 
 * @param userId 
 * userId of the user to fetch.
 * 
 */
export const getSingleUser = async (
    userId: string,
): Promise<any> => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.GET_ALL_USERS);
    const params = {
        filter: createFiltersString([userId], 'user_id'),
    }
    const urlWithParams = appendParamsToString(fullUrl, params)
    const rawRes = await authenticatedFetchRequest(urlWithParams, "GET");

    if (!rawRes?.users || !Array.isArray(rawRes?.users)) {
        throw new Error(`Expected array but got: ${rawRes?.users}`);
    }
    return userFromApiRes(rawRes.users[0]);
}

/**
 * Fetches and returns the list of groups the user has access to.
 * 
 * @param userId 
 * userId of the user whose groups are being fetched.
 * 
 */
export const getUsersGroups = async (userId: string): Promise<any> => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.GET_ALL_GROUPS_FOR_USER);
    const params = {
        userId,
    }
    const urlWithParams = appendParamsToString(fullUrl, params)

    return await authenticatedFetchRequest(urlWithParams, "GET");
}

/**
 * Fetches and returns the list of all groups.
 * 
 * @param setDisplayableRowCount
 * State updating function for displayableRowCount (total number of groups)
 * @param limit
 * Max number of results per page
 * @param offsest
 * Offset of results to fetch
 * */
export const getAllGroups = async (
    limit = 50,
    offset = 0
) => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.GET_ALL_GROUPS);
    const params = {
        limit: limit.toString(),
        offset: offset.toString(),
    }
    const urlWithParams = appendParamsToString(fullUrl, params);
    const rawRes = await authenticatedFetchRequest(urlWithParams, 'GET');

    return { groups: rawRes?.groups, totalCount: rawRes?.total };
}

/**
 * Takes a list of groups the user has access to and returns a list of objects
 * where the key is an application name and the value is a list of groups that
 * belong to that application.
 * 
 * @param raw 
 * List of groups the user has access to.
 * 
 */
export const getGroupsGroupedByApp = (raw: any[]): any[] => {
    if (!Array.isArray(raw)) {
        throw new Error(`getGroupsGroupedByApp expected array of groups but got ${raw}`)
    }

    return raw.map((obj) => (obj.application_id))
        .filter((str, idx, arr) => arr.indexOf(str) === idx)
        .map((appName) => {
            const groups = raw.filter((obj) => obj.application_id === appName);
            return { [appName]: groups };
        });
}

/**
 * Required props for the API to create user.
 * 
 * @param givenName
 * The user's given name.
 * 
 * @param familyName
 * The user's family name.
 * 
 * @param userName
 * The user's unique username, which is their email address.
 */
type CreateUserProps = {
    givenName: string,
    familyName: string,
    userName: string,
}

/**
 * Calls the API endpoint for creating a user.
 * 
 * @param user 
 * CreateUserProps object with string values for givenName, familyName, and
 * userName.
 * 
 */
export const createUser = async (user: CreateUserProps): Promise<any> => {
    // This is hard-coded for now but will presumably be a real setting in the
    // future.
    const PREFERRED_LANGUAGE = 'en';

    const endpoint = getFullUrlFromEndpoint(endpoints.ADD_USER);
    return await authenticatedFetchRequest(endpoint, "POST", { ...user, preferredLanguage: PREFERRED_LANGUAGE });
}

/**
 * Deletes the users with the usernames passed as string array.
 * 
 * @param userEmails
 * String array where each entry is the email of a user to delete.
 * 
 */
export const deleteUsers = async (userEmails: string[]) => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.UPDATE_USER)

    // Create an array of delete requests and then wait for them all to
    // resolve. The API doesn't currently allow for batch deletion, so this
    // is the workaround.
    const promises = userEmails.map((email) => {
        const urlWithParams = appendParamsToString(fullUrl, { userName: email });
        return authenticatedFetchRequest(urlWithParams, 'DELETE');
    })
    await Promise.all(promises);
    return { deletedUsers: userEmails }
}

/**
 * Edits the user data passed to function using user email
 * 
 * 
 * @param userEmail 
 * String array where each entry is the email of a user to delete.
 * 
 */
export const editUser = async (
    userEmail: string,
    userDataToUpdate: Record<string, string>,
    data: Record<string, string>
) => {
    const formattedUserDataToUpdate = convertUserData(userDataToUpdate, data, userEmail);
    const fullUrl = getFullUrlFromEndpoint(endpoints.UPDATE_USER);

    return await authenticatedFetchRequest(fullUrl, 'PATCH', { ...formattedUserDataToUpdate });
}

/** 
 * Fetches all users in a group
 * 
 * @param groupId
 * Id of group to fetch
 * @param limit
 * Maximum number of results per page
 * @param offset
 * Offset of results to fetch
*/
export const getAllUsersInGroup = async (
    groupId: string,
    limit: number = 50,
    offset: number = 0
) => {
    const fullUrl = getFullUrlFromEndpoint(endpoints.GET_ALL_USERS_IN_GROUP);
    const params = {
        groupId,
        limit: limit.toString(),
        offset: offset.toString(),
    }
    const urlWithParams = appendParamsToString(fullUrl, params);
    const rawRes = await authenticatedFetchRequest(urlWithParams, 'GET');

    return ({ users: rawRes?.users?.map((obj) => userFromApiRes(obj)), totalUserCount: rawRes?.total })
}

/**
 * Takes a list of users, a list of groupIds, and the fullUrl for the API
 * endpoint to call (either add or remove users to groups). Calls the endpoint
 * and returns an object with two arrays: successUsers and errorUsers.
 * 
 * @param users 
 * List of users to assign to groups.
 * 
 * @param groupIds 
 * List of groupIds to assign users to.
 * 
 * @param fullUrl
 * Full URL for the API endpoint to call.
 * 
 * @returns
 * Object with two arrays: successUsers and errorUsers. successUsers is a list
 * of User objects for users that were successfully added to/removed from
 * groups. errorUsers is a list of User objects for users that were not 
 * successfully added to/removed from groups. Each User object in errorUsers 
 * will have an error property with a string describing the error.
 */
export const addOrRemoveUsersFromGroups = async (
    users: User[],
    groupIds: string[],
    fullUrl: string,
) => {
    const usersById = {};
    users.forEach((u) => {
        if (u.id) {
            usersById[u.id] = u;
        }
    });

    // Nested loop here: for each userId, create a list of objects pairing it
    // with each groupId. Then flatten the list of lists into a single list.
    const body = Object.keys(usersById).map((userId) => groupIds.map((groupId) => ({
        userId,
        groupId,
    }))).flat();

    const rawRes = await authenticatedFetchRequest(fullUrl, 'POST', body);

    if (!Array.isArray(rawRes)) {
        throw new Error(`Expected api result to be array but got: ${rawRes}`);
    }

    const successUsers = [];
    const errorUsers = [];
    rawRes.forEach((obj) => {
        if (obj?.result?.error) {
            errorUsers.push({
                ...usersById[obj.userId],
                error: obj.result.message || "Error",
            });
        } else {
            successUsers.push(usersById[obj.userId]);
        }
    });
    return {
        successUsers,
        errorUsers,
    };
}

/**
 * Takes a list of users and a list of groupIds and calls the API endpoint to
 * assign those users to those groups.
 * 
 * @param users 
 * List of users to assign to groups.
 * 
 * @param groupIds 
 * List of groupIds to assign users to.
 * 
 * @returns 
 * Object with two arrays: successUsers and errorUsers. successUsers is a list
 * of User objects for users that were successfully added to groups. errorUsers
 * is a list of User objects for users that were not successfully added to
 * groups. Each User object in errorUsers will have an error property with a
 * string describing the error.
 */
export const assignUsersToGroups = async (users: User[], groupIds: string[]) => {
    return addOrRemoveUsersFromGroups(
        users,
        groupIds,
        getFullUrlFromEndpoint(endpoints.ASSIGN_USER_TO_GROUP),
    );
}

/**
 * Takes a list of users and a list of groupIds and calls the API endpoint to
 * remove those users from those groups.
 * 
 * @param users 
 * List of users to remove from groups.
 * 
 * @param groupIds 
 * List of groupIds from which to remove users.
 * 
 * @returns 
 * Object with two arrays: successUsers and errorUsers. successUsers is a list
 * of User objects for users that were successfully removed from groups. 
 * errorUsers is a list of User objects for users that were not successfully
 * removed from groups. Each User object in errorUsers will have an error
 * property with a string describing the error.
 */
export const removeUsersFromGroups = async (users: User[], groupIds: string[]) => {
    return addOrRemoveUsersFromGroups(
        users,
        groupIds,
        getFullUrlFromEndpoint(endpoints.REMOVE_USER_FROM_GROUP),
    );
}
