import { paths } from '../../shared/constants';
import userMgmtIcon from '../../assets/userMgmtIcon.svg';
import genaiIcon from '../../assets/genaiIcon.svg';

export const settingsConfig = [
  {
    title: "User Management",
    path: `/${paths.USER_MGMT}`,
    icon: userMgmtIcon,
    iconAltText: "User Management",
  },
  {
    title: "Group Management",
    path: `/${paths.GROUP_MGMT}`,
    icon: userMgmtIcon,
    iconAltText: "Group Management",
  },
  {
    title: "GenAI Studio",
    path: `${paths.GEN_AI}`,
    icon: genaiIcon,
    iconAltText: "GenAI",
  }
]
