import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userListReducer } from "./reducers/userListReducer";
import { activeUserCountReducer } from "./reducers/activeUserCountReducer";
import { deactivatedUserCountReducer } from "./reducers/deactivatedUserCountReducer";
import { pendingUserCountReducer } from "./reducers/pendingUserCountReducer";
import { totalUserCountReducer } from "./reducers/totalUserCountReducer";
import { groupListReducer } from "./reducers/groupListReducer";
import { totalGroupCountReducer } from "./reducers/totalGroupCountReducer";

// const initialState = {};

const reducer = combineReducers({
  activeUserCount: activeUserCountReducer,
  deactivatedUserCount: deactivatedUserCountReducer,
  pendingUserCount: pendingUserCountReducer,
  totalUserCount: totalUserCountReducer,
  userList: userListReducer,
  groupList: groupListReducer,
  totalGroupCount: totalGroupCountReducer
});

// If no preloaded state is provided, each reducer will be run with no input,
// and the field will be set to whatever the reducer returns as a default value
// when it receives no input.
// Or you can preload any state you want, as long as it matches the shape of the
// fields specified in the reducer.
const store = configureStore({
  reducer,
  // preloadedState: initialState,
});

export default store;
