import * as React from "react";
import "./UserDetailsAppGroups.scss";
import { Typography, Button } from "@aiops/styleguide";
import { paths } from '../../shared/constants';
import { navigateToUrl } from "single-spa";
import { Group } from "../../shared/models/group";

/**
 * Type for props of UserDetailsAppGroups component.
 * 
 * @param appName
 * The name of the app.
 * 
 * @param groups
 * The list of groups for the app. Each group should have a `group_display_name` 
 * property.
 * 
 * @param basePath
 * Path for settings microsolution, pulled from root.component props
 */
export type UserDetailsAppGroupsProps = {
  appName: string,
  groups: Group[],
  basePath: string
};

/**
 *  Renders a list of groups for a given app.
 */
const UserDetailsAppGroups = (
  { appName,
    groups,
    basePath
  }: UserDetailsAppGroupsProps) => {

  const styledGroupLink = (group: Group, key: string | number) => {
    const link = `/${basePath}/${paths.GROUP_MGMT}/${group.group_id}`
    return (
      <Button
        key={key}
        className="group-link"
        onClick={() => navigateToUrl(link)}
        data-testid={`group-link-${group.group_display_name}`}
      >
        <Typography variant="paragraph1">
          {group.group_display_name}
        </Typography>
      </Button>
    )
  }

  return (
    <div className="user-details-app-groups clickable">
      <Typography variant="subheading1">
        {appName}
      </Typography>
      {groups.map((g, idx) => styledGroupLink(g, idx))}
    </div>
  );
};

export default UserDetailsAppGroups;
