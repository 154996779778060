import { paths } from '../../shared/constants';
import { Button, useModal, StickyAppFooter } from "@aiops/styleguide";
import { navigateToUrl } from "single-spa";

/**
 * Type for props of UserDetailsFooter component.
 * 
 * @param editMode
 * When true, screen is in edit mode and user has ability to cancel or save
 * changes.
 * 
 * @param setEditMode
 * Function that sets edit mode to true or false.
 * 
 * @param saveAndExit
 * Function that triggers saving changes and exits edit mode.
 * 
 *  * @param resetForm
 * Function that resets form changes
 * 
 * @param editedFields
 * Object with a key value pair for each edited field
 * 
 * @param basePath
 * Path for settings microsolution, pulled from root.component props
 */
export type UserDetailsFooterProps = {
  editMode: boolean,
  setEditMode: (arg: boolean) => void,
  saveAndExit: () => void,
  resetForm: () => void,
  editedFields: Record<string, any>,
  basePath: string
};

/**
 *  Renders a footer at the bottom of the user details page with a button to 
 *  return to the UserMgmt screen and buttons to either cancel or save and exit 
 *  from edit mode.
 */
const UserDetailsFooter = (
  { editMode,
    setEditMode,
    saveAndExit,
    resetForm,
    editedFields,
    basePath
  }: UserDetailsFooterProps) => {
  // Hooks 
  const { openModal, closeModal } = useModal();

  const pathToUserMgmt = `/${basePath}/${paths.USER_MGMT}`;

  // Boolean showing if any user forms fields have been changed 
  const editedFieldsExist = Object.keys(editedFields).length !== 0;

  const backButton = (
    <Button
      variant="outlined"
      onClick={() => {
        if (editedFieldsExist) {
          openModal({
            headlineText: 'Do you want to save before you exit?',
            cancelButtonText: 'Discard',
            onCancel: () => {
              closeModal()
              navigateToUrl(pathToUserMgmt)
            },
            confirmationButtonText: 'Save',
            onConfirm: () => {
              closeModal()
              saveAndExit()
            }
          })
        } else {
          navigateToUrl(pathToUserMgmt)
        }
      }
      }
      data-testid="back-button"
    >
      Back
    </Button>
  )

  // Buttons that either cancel or save and exit from edit mode.
  const cancelAndSaveButtons = (
    <div className="row">
      <Button
        variant="outlined"
        onClick={() => {
          setEditMode(false)
          resetForm()
        }}
        data-testid="cancel-button"
      >
        Cancel
      </Button>
      <Button
        variant="filled"
        // Saving Changes is disabled if no fields have been edited
        disabled={!editedFieldsExist}
        onClick={saveAndExit}
        data-testid="save-button"
        style={{ marginLeft: '1rem' }}
      >
        Save Changes
      </Button>
    </div >
  )

  return (
    <StickyAppFooter>
      {backButton}
      {editMode ? cancelAndSaveButtons : null}
    </StickyAppFooter>
  );
};

export default UserDetailsFooter;
