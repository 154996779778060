import "./SettingsCard.scss";
import { useNavigate } from "react-router-dom";
import { Typography } from "@aiops/styleguide";

/**
 * Type for props of SettingsCard component.
 */
export type SettingsCardProps = {
  title: string,
  path: string,
  icon: string,
  iconAltText: string,
};

/**
 * Renders a card with title and icon, which when clicked takes the user to a
 * settings page.
 */
const SettingsCard = ({ title, path, icon, iconAltText, }: SettingsCardProps) => {

  const navigate = useNavigate();

  return (
    <button
      data-testid="settings-card"
      className="settings-card"
      onClick={() => navigate(path)}
    >
      <img src={icon} alt={iconAltText} />
      <Typography variant="heading6-light" className="settings-card-title">
        {title}
      </Typography>
    </button>
  );
};

export default SettingsCard;
