import store from "../store";
import { setActiveUserCount } from "../store/actions/activeUserCountActions";
import { setDeactivatedUserCount } from "../store/actions/deactivatedUserCountActions";
import { setPendingUserCount } from "../store/actions/pendingUserCountActions";
import { setTotalUserCount } from "../store/actions/totalUserCountActions";
import { endpoints } from "../constants";
import { utils } from "@aiops/styleguide";
import { getIdToken } from '@aiops/auth-util';


// This application ID is specifically for making API requests and has nothing
// to do with the settings app's application ID.
const API_REQUEST_APPLICATION_ID = "PLATFORM";

/**
 * Given a url, method name, and body, makes a fetch request to the url that
 * includes the user's id token as well as default header values.
 * 
 * @param url 
 * The full api endpoint (including parameters, if any) to which the request
 * should be made.
 * 
 * @param method 
 * The method to invoke, as string: GET, POST, PUT, PATCH, or DELETE.
 * 
 * @param body 
 * Either null or an object of key/value pairs that will be stringified in the
 * actual request. If the method is GET or DELETE the body must be null, and 
 * the function will throw an error if you include a body. Body is null by
 * default.
 * 
 */
export const authenticatedFetchRequest = async (
    url: string,
    method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE',
    body: null | Record<string, any> = null,
) => {
    if (!url) {
        throw new Error(`Cannot make request without url: ("${url}")`);
    }
    if ((method === "GET" || method === "DELETE") && body) {
        throw new Error(`Cannot make ${method} request with body: ("${body}")`);
    }
    const req = {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getIdToken()}`,
            applicationId: API_REQUEST_APPLICATION_ID,
        },
        body: body ? JSON.stringify(body) : undefined,
    }
    return await fetch(url, req)
        .then(async(res) => {
            if (!res.ok) {
              // try to pull error message from bad response
              try {
                const json = await res.json();
                return ({error: {message: json.message}});
              } catch {
                throw new Error(`Unable to make ${method} request to: ${url}`);
              }
            }
            else {
                return res.json()
            }
        });
}

/**
 * Takes a string or array of strings and appends it/them to the deployment's
 * base url.
 * 
 * @param endpoint 
 * The string or strings to append to the base url.
 * 
 * @param usePrefix 
 * When true, the app's prefix will be added in between the base url and the
 * provided endpoint(s) (ie: api.com/stage/settings-prefix/endpoint). When false
 * it will not (ie: api.com/stage/endpoints). True by default.
 * 
 */
export const getFullUrlFromEndpoint = (endpoint: string | string[], usePrefix = true): string => {
    const asArr = Array.isArray(endpoint) ? endpoint : [endpoint];
    return utils.appendToBaseUrl([usePrefix ? endpoints.PREFIX : "", ...asArr]);
}

/**
 * Takes a string and an object with strings as keys and values, and returns the
 * object as a series of query parameters appended to the string.
 * 
 * @param str String to which to append query parameters (ie a url)
 * @param params Object of query parameters and corresponding string values
 */
export const appendParamsToString = (str: string, params: Record<string, string>): string => {
    // Only include paramters for which there is a value
    const paramsAsStr = Object.keys(params)
        .map((key) => params[key] ? `${key}=${params[key]}` : '')
        // filter out falsey values; specifically check for 0 in case a number
        // snuck through as a number instead of a string
        .filter((p) => p || p.toString() === "0")
        .join('&');
    return `${str}?${paramsAsStr}`;
}

/**
 * Returns a string of filters to be used in a query string, of the format 
 * "key=[filter1,filter2,filter3]", encoded for use in a URL.
 * 
 * @param filters 
 * List of string filters.
 * 
 * @param key 
 * The key to filter by.
 * 
 */
export const createFiltersString = (filters: string[], key: string): string => {
    if (!key.trim()) {
        throw new Error(`Key must be a non-empty string: ${key}`);
    }
    const trimmed = filters.filter((f) => f.trim());
    if (trimmed.length === 0) {
        return "";
    }
    return encodeURIComponent(`${key}=[${trimmed.join(',').trim()}]`)
}

export const setUserCountsFromRawApiReponse = (rawRes: Record<string, any>): void => {
    // In case the API response doesn't include all the counts, set them to 0
    store.dispatch(setActiveUserCount(parseInt(rawRes.active || 0)));
    store.dispatch(setDeactivatedUserCount(parseInt(rawRes.deactivated || 0)));
    store.dispatch(setPendingUserCount(parseInt(rawRes.pending || 0)));
    store.dispatch(setTotalUserCount(parseInt(rawRes.total || 0)));
}

/**
 * @param fieldsToConvert
 *  Fields that have been edited with frontend naming conventions
 * 
 * @param email
 *  Email of user to update
 * 
 * @returns
 * Returns updated edit user form fields mapping into format API requires
 */
export const convertUserData = (fieldsToConvert: Record<string, string>, data: Record<string, string>, email: string) => {
    const helperObj = {};
    const fieldMapping = { familyName: 'last_name', givenName: 'first_name', status: "state" };
    const fieldKeys = Object.keys(fieldsToConvert);
    fieldKeys.forEach((key) => {
        const newKey = fieldMapping[key];
        const value = data[key];
        helperObj[newKey] = value;
    })
    helperObj["user_name"] = email
    return helperObj;
}
