import { useState, useEffect, } from "react";
import "./UserManagementPage.scss";
import {
  Table,
  Selectability,
  TableFooterPaginationProps,
  useModal,
  useToast,
  FilterBar,
  FilterButtonProps,
} from "@aiops/styleguide";
import { User } from "../../shared/models/user";
import { getUsers } from "../../shared/apiHelper";
import { columns, filters as rawFilters, } from './config';
import { useSelector, useDispatch } from "react-redux";
import { setUserList } from "../../shared/store/actions/userListActions";
import UserManagementFooter from "../UserManagementFooter";
import DeleteUserModal from "../DeleteUserModal";
import {
  getStatusClassName,
  typographyFromVariant,
  actionsCellFromUser,
  trashButton,
  titleAndSearchRow,
  updateFilterNames,
  clickableUserName,
} from './util';
import { navigateToUrl } from "single-spa";
import { pagination, paths } from '../../shared/constants';

/** Type props for UserManagementPage
 * @param basePath
 * Base route for settings microapp
 */
type UserManagementPageProps = {
  basePath: string;
}
/**
 *  Renders the user management page with a table and filters.
 */

const UserManagementPage = (
  { basePath }: UserManagementPageProps) => {

  // Hooks hooks and more hooks
  const dispatch = useDispatch();
  const {
    activeUserCount,
    deactivatedUserCount,
    pendingUserCount,
    totalUserCount,
    userList,
  } = useSelector((state: {
    activeUserCount: number,
    deactivatedUserCount: number,
    pendingUserCount: number,
    totalUserCount: number,
    userList: User[],
  }) => state);
  const { openModal } = useModal();
  const { setCurrentToast } = useToast();


  // True when table is loading
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  // Array of IDs of users in selected rows.
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);

  // Zero-indexed number of the page you're currently on.
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Number of rows to display per page.
  const [rowsPerPage, setRowsPerPage] = useState<number>(pagination.ROWS_PER_PAGE_DEFAULT);

  // The number of rows to display in the table, which changes depending on
  // which filters if any are selected.
  const [displayableRowCount, setDisplayableRowCount] = useState<number>(totalUserCount);

  // Sets the filter list such that none are selected/all are toggled off.
  const resetFilters = () => {
    const newFilters = [...filters];
    newFilters.forEach((f) => f.selected = false);
    setFilters(newFilters);
  }

  // The filters affecting the table's results
  const [filters, setFilters] = useState<(FilterButtonProps)[]>(rawFilters);

  // Whenever the filters object, current page, or number of rows per page
  // changes, update the user list.
  useEffect(() => {

    // Makes the api request to get a new user list given the current page,
    // number of rows per page, and filters.
    const updateUsers = async (_filters: string[]) => {
      setTableLoading(true);
      try {
        const res = await getUsers(_filters, rowsPerPage, rowsPerPage * currentPage);
        dispatch(setUserList(res));
      } catch (error) {
        console.error("could not get fetch users: ", error);
        dispatch(setUserList([]));
      }
      setTableLoading(false);
    }

    // Dump the user list before making the api call.
    dispatch(setUserList([]));

    // The actual filter strings to apply to the api request (which are the IDs/
    // status enum values in the filter objects).
    let filts = filters.map((f) => f.selected ? f.id : "").filter((f) => f);

    // If every non-disabled filter is selected, deselect all filters and
    // perform the API request with no filters. (Must check that length is
    // greater than zero to avoid an infinite loop of resetting filters.)
    if (filts.length > 0 && filts.length === filters.filter((f) => !f.disabled).length) {
      filts = [];
      resetFilters();
    }

    updateUsers(filts);
  }, [filters, currentPage, rowsPerPage]);


  // Update the filters names and the display total any time the number of rows,
  // number of users in any category, or filters change.
  useEffect(() => {

    // Add the number of users in each category to the filter names.
    updateFilterNames(filters, activeUserCount, pendingUserCount, deactivatedUserCount);

    // The total count of users that will be displayed with the current filters
    // in place (ie: add each filter's count to the total if it is selected).
    const filteredCount = filters.reduce(
      (acc, filter) => filter.selected ? acc + (filter.count || 0) : acc,
      0
    );

    // If the filtered count is zero no filters are in place, so display the
    // total user count.
    setDisplayableRowCount(filteredCount === 0 ? totalUserCount : filteredCount);
  }, [
    activeUserCount,
    deactivatedUserCount,
    pendingUserCount,
    totalUserCount,
    filters,
  ]);

  // Whenever filters change, set the current page to zero.
  useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const headerCells: any = {};

  columns.forEach((col) => {
    const field = col.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: col.title,
      isCurrentSortColumn: false,
    }
  });

  const onSelelectAllToggle = (val: boolean) => {
    if (val) {
      setSelectedUserNames(userList.map((user) => user.userName));
    } else {
      setSelectedUserNames([]);
    }
  }


  // Object that describes how the Table's selectability should function.
  const tableSelectability: Selectability = {
    rowIsSelectable: () => true,
    rowIsSelected: (user) => selectedUserNames.includes(user.userName),
    selectAllCheckbox: true,
    selectAllCheckboxDisabled: userList.length === 0,
    selectAllCheckboxValue: selectedUserNames.length === userList.length,
    selectAllCheckboxIndeterminate: selectedUserNames.length > 0 && selectedUserNames.length < userList.length,
    onSelectAllToggle: onSelelectAllToggle,
    onSelectRowToggle: (val, user) => {
      if (val) {
        setSelectedUserNames([
          ...selectedUserNames,
          user.userName,
        ]);
      } else {
        setSelectedUserNames([...selectedUserNames].filter((userName) => userName !== user.userName));
      }
    },
  }

  const tablePagination: TableFooterPaginationProps = {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions: pagination.ROWS_PER_PAGE_OPTIONS,
    totalResults: displayableRowCount,
    onNextPage: () => {
      const maxPageNum = Math.ceil(displayableRowCount / rowsPerPage) - 1;
      setCurrentPage(Math.min(currentPage + 1, maxPageNum));
      onSelelectAllToggle(false);
    },
    onPreviousPage: () => {
      setCurrentPage(Math.max(0, currentPage - 1));
      onSelelectAllToggle(false);
    },
    onSelectRowsPerPage: (r) => {
      // When changing the number of results per page, dump the user back to the
      // first page.
      setCurrentPage(0);
      setRowsPerPage(r);
      onSelelectAllToggle(false);
    },
  }

  const confirmDeleteUsers = (userNames: string[]) => {
    openModal({
      children: <DeleteUserModal
        setToast={setCurrentToast}
        refreshTable={refreshTable}
        userNameOrNames={userNames}
      />
    });
  }

  // Reloads the table and deselects any selected rows.
  const refreshTable = () => {
    setSelectedUserNames([]);

    // TODO: Find something less hacky
    setFilters([...filters]);
  }

  return (
    <div className="col">
      <div className="user-table">
        {titleAndSearchRow()}
        <div className="row justified-row">
          {trashButton(() => confirmDeleteUsers(selectedUserNames), selectedUserNames.length < 1)}
          <FilterBar
            filterButtons={filters}
            // @ts-ignore
            onChange={setFilters}
          />
        </div>
        <Table
          tableIsLoading={tableLoading}
          columnKeys={columns.map((col) => col.field)}
          headerCells={headerCells}
          selectability={tableSelectability}
          rows={userList.map((user) => ({
            ...user,
            fullName: clickableUserName(
              user,
              () => navigateToUrl(`/${basePath}/${paths.USER_MGMT}/${user.id}`)
            ),
            email: typographyFromVariant(user.email, "caption1c"),
            statusString: typographyFromVariant(user.statusString,
              "caption1",
              getStatusClassName(user.statusString)
            ),
            actions: actionsCellFromUser(user,
              confirmDeleteUsers
            ),
          }))}
          pagination={tablePagination}
        />
      </div>
      <UserManagementFooter />
    </div >
  );
};

export default UserManagementPage;
