import "./GroupManagementPage.scss";
import {
  Button,
  Typography,
  StickyAppFooter,
} from "@aiops/styleguide";
import { navigateToUrl } from "single-spa";
import { useEffect, useState } from "react";
import GroupManagementTable from "../GroupManagementTable";
import { getAndSetGroups } from "./util";
import { useDispatch } from "react-redux";
import { pagination } from "../../shared/constants";
import "./GroupManagementPage.scss"

/** Type props for GroupManagementPage
 * @param basePath 
 * Base route for settings microapp
*/
type GroupManagementPageProps = {
  basePath: string;
};

const GroupManagementPage = (
  { basePath }: GroupManagementPageProps) => {
  //Redux dispatch hook
  const dispatch = useDispatch()
  // TableLoading state
  const [tableIsLoading, setTableIsLoading] = useState(false);
  // List of all groups
  const [groups, setGroups] = useState([])

  // Zero-indexed number of the page you're currently on.
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Number of rows to display per page.
  const [rowsPerPage, setRowsPerPage] = useState<number>(pagination.ROWS_PER_PAGE_DEFAULT);

  // Loads and sets groups on page load
  useEffect(() => {
    setTableIsLoading(true)
    getAndSetGroups(setGroups, setTableIsLoading, dispatch, rowsPerPage, currentPage * rowsPerPage)
  }, [rowsPerPage, currentPage])

  return (
    <div className="col">
      <div className="user-table">
        <Typography variant="heading2-titles" className="title-row">
          Group Management
        </Typography>
        <GroupManagementTable
          tableIsLoading={tableIsLoading}
          groups={groups}
          basePath={basePath}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
      <StickyAppFooter>
        <Button
          variant="outlined"
          onClick={() => navigateToUrl(`/${basePath}`)}
          data-testid="back-button"   >
          Back
        </Button>
      </StickyAppFooter>
    </div >
  );
}

export default GroupManagementPage;