//Mapping of API group field and display name
export const columns = [
  {
    field: 'fullName',
    title: 'User',
  },
  {
    field: 'actions',
    title: 'Actions',
  },
];