import * as types from "../types";

export const groupListReducer = (
  userList = [],
  action = { type: types.GROUP_LIST_SET, payload: [] },
) => {
  switch (action.type) {
    case (types.GROUP_LIST_SET):
      return action.payload;
    default:
      return userList;
  }
}
