import { useEffect, useState } from "react";
import "./AddUserToGroupModal.scss";
import { useForm } from 'react-hook-form';
import {
  useModal,
  confirmationModalButtonRow,
  CircularProgress,
  FormAutocomplete,
} from "@aiops/styleguide";
import { getUsers, assignUsersToGroups } from "../../shared/apiHelper";
import { User } from "../../shared/models/user";
import {
  getToastFromUsers,
  renderAutocompleteOption,
  renderTag,
} from "./utils";

export interface IUserList {
  selectedUsers: User[],
}

/**
 * Type for props of AddUserToGroupModal component.
 */
export type AddUserToGroupModalProps = {
  setToast: (arg: any) => void,
  openModal: (arg: any) => void,
  usersInGroup: User[],
  groupId: string,
  refreshTable: () => void,
};

/**
 * Returns a modal with a dropdown for multiselecting user(s) to add to a group.
 * 
 */
const AddUserToGroupModal = ({ setToast, openModal, usersInGroup, groupId, refreshTable, }: AddUserToGroupModalProps) => {

  // For initial development only, fetch all users to populate the list. So this
  // limit needs to be greater than the number of users in the database. This
  // whole approach should be refactored later, to fetch some small number of
  // users and then search and re-search as the user types.
  const TEMP_USER_COUNT = 500;

  const [users, setUsers] = useState<User[]>([]);
  const [fetchingUsers, setFetchingUsers] = useState<boolean>(true);
  const [addingUsers, setAddingUsers] = useState<boolean>(false);
  const { closeModal, blockCloseModal } = useModal();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setFetchingUsers(true);
        const users = await getUsers([], TEMP_USER_COUNT, 0);

        // Filter out users that are already in the group.
        const usersInGroupObj = {};
        usersInGroup.forEach((u) => usersInGroupObj[u.id] = true);
        setUsers(users.filter((u) => !usersInGroupObj[u.id]));
      } catch (e) {
        console.error("error fetching users: ", e);
      } finally {
        setFetchingUsers(false);
      }
    }
    fetchUsers();
  }, []);

  // Get the handleSubmit and control functions from react-hook-form
  const { handleSubmit, control } = useForm<IUserList>({
    defaultValues: {
      selectedUsers: [],
    }
  });

  const dropdown = () => {
    return (
      <FormAutocomplete
        label="Choose User(s)"
        data={users.map((user) => ({
          label: `${user.fullName || "Unknown User"} (${user.email || "Unknown Email"})`,
          value: user,
          key: user.id || user.email,
        }))}
        width={400}
        size="small"
        multiple={true}
        disableCloseOnSelect={true}
        // what you click on to select something (the options in the menu)
        renderOption={(sel, lab) => renderAutocompleteOption(sel, lab)}
        // the tag that shows up in the textfield (ie list of selected users)
        renderTag={renderTag}
        control={control}
        controlLabel="selectedUsers"
      />
    )
  }

  const onAddUsers = async (formObj: { selectedUsers: any[] }) => {
    blockCloseModal(true);
    setAddingUsers(true);
    const { selectedUsers } = formObj || { selectedUsers: [] }
    try {
      const users = selectedUsers.map((u) => u?.value).filter((u) => u);
      if (users.length === 0) {
        throw new Error("User list cannot have 0 users");
      }
      const { successUsers, errorUsers } = await assignUsersToGroups(users, [groupId]);
      const successToast = getToastFromUsers(successUsers, true, 'ADD');
      const errorToast = getToastFromUsers(errorUsers, false, 'ADD', openModal, closeModal);
      // These toasts will either be valid toast messages (if the arrays passed
      // to getToastFromUsers are non-empty) or null (for empty arrays, ie if 
      // no successes/no errors), so filter to get only truthy toast messages.
      // It should never be the case that both are null (if so there's a problem
      // with assignUsersToGroups and/or the endpoint) but in this case the modal
      // will close without showing a toast.
      setToast([successToast, errorToast].filter((t) => t));
      refreshTable();
    } catch (e) {
      const count = selectedUsers.length;
      setToast({
        severity: "error",
        message: `Error: ${e.message || `Unable to add ${count} user${count > 1 ? 's' : ''} to group.`}`,
      });
    } finally {
      blockCloseModal(false);
      setAddingUsers(false);
      closeModal();
    }
  }

  return (
    <div className="col">
      {fetchingUsers || addingUsers ? <CircularProgress /> : dropdown()}
      {!fetchingUsers && !addingUsers && confirmationModalButtonRow("Cancel", closeModal, "Add", () => handleSubmit(onAddUsers)())}
    </div>
  );
};

export default AddUserToGroupModal;
