import { userStates } from '../constants';

export const objKeys = {
    ID: "user_id",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    STATUS_ENUM: "state",
    EMAIL: "user_name",
    USER_NAME: "user_name",
}

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    userName: string,
    email: string,
    statusEnum: string,
    statusString: string,
    rawApiRes: Record<string, any>,
}

/**
 * Takes a value as an input and returns that value as a string (if it's a 
 * string or a number that isn't NaN), or "-" if the value is an empty string,
 * undefined, null, or NaN.
 * 
 * The point is to be able to turn undefined/null/falsey/nonexistent values into
 * a placeholder string to indicate that there's nothing to display for that
 * value.
 * 
 * @param value 
 * Whatever value should be scrubbed.
 */
export function scrub(value: string | number | undefined | null) {
    const displayWhenBlank = '-'
    if (typeof value === 'number') {
        if (Number.isNaN(value)) {
            return displayWhenBlank;
        }
        return value.toString();
    }
    return value || displayWhenBlank;
}

export const statusStringFromEnum = (status) => {
    const map = {
        [userStates.ACTIVE]: "Active",
        [userStates.PENDING]: "Pending",
        [userStates.INACTIVE]: "Deactivated",
    };
    return scrub(map[status]);
}

/**
 * Returns a full name when passed given and family names.
 * 
 * @param given 
 * The user's given name (first name in the west).
 * 
 * @param family 
 * The user's family name (last name in the west).
 * 
 * @param familyNameFirst 
 * When true, the full name will be formatted so that the family name comes
 * first (ie Doe John instead of John Doe).
 * 
 */
export const getFullName = (given: string, family: string, familyNameFirst: boolean = false) => {
    if (typeof given !== "string" || typeof family !== "string") {
        return scrub(null);
    }

    if (!given.trim() && !family.trim()) {
        return scrub(null);
    }

    return familyNameFirst
        ? `${family} ${given}`
        : `${given} ${family}`;
}

/**
 * Takes an object as input and returns a user.
 * 
 * @param obj 
 * Any object (but typically the result of an api call that returns a user).
 */
export const userFromApiRes = (obj: Record<string, any>): User => {
    return ({
        id: scrub(obj[objKeys.ID]),
        firstName: scrub(obj[objKeys.FIRST_NAME]),
        lastName: scrub(obj[objKeys.LAST_NAME]),
        fullName: getFullName(obj[objKeys.FIRST_NAME], obj[objKeys.LAST_NAME]),
        userName: scrub(obj[objKeys.USER_NAME]),
        email: scrub(obj[objKeys.EMAIL]),
        statusEnum: obj[objKeys.STATUS_ENUM],
        statusString: statusStringFromEnum(obj[objKeys.STATUS_ENUM]),
        rawApiRes: obj,
    });
}
