import { useEffect, useState } from "react";
import "./UserDetailsPage.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Typography,
  useToast,
} from "@aiops/styleguide";
import UserDetailsHeadingRow from "../UserDetailsHeadingRow";
import UserDetailsBody from "../UserDetailsBody";
import UserDetailsFooter from "../UserDetailsFooter";
import { navigateToUrl } from "single-spa";
import { paths } from '../../shared/constants';
import { getToast } from "../UserManagementPage/util";
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateUserDetails, fetchSingleUser, getAndSetUserGroups, resetForm } from "./utils";


/**
 * Form field schema 
 */
const schema = z.object({
  givenName: z.string().min(1, 'Owner name is required'),
  familyName: z.string().min(1, 'Family name is required'),
  // Placeholder for future group state form updates
  // groups: z.array(z.object({ value: z.string(), label: z.string() })).min(1, 'Group is required'),
  status: z.string()
});
/** Type props for UserDetailsPage
 * @param basePath
 * Base route for settings microapp
 */
type UserDetailsPageProps = {
  basePath: string;
}
/**
 * Page that displays information about a user, and allows the user viewing the
 * page to make edits.
 */
const UserDetailsPage = ({ basePath }: UserDetailsPageProps) => {
  // So many hooks
  const { id } = useParams<{ id: string }>();
  const { userList } = useSelector((state: any) => state);
  const { setCurrentToast } = useToast();

  // So much state
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  /* useForm hook exposes useful items
  * handleSubmit validates if form is submittable 
  * - (ie are req fields missing or don't meet validation logic)
  * reset sets the form state back to the default if nothing is passed to it or to the object passed to it
  * control is an object passed to form fields that controls their state
  * formState is an object that holds information on the user's interaction with the form
  * - See react hook form documentation for more information
  * dirtyFields is one object within formState that contains a key value of fieldName: true for each user-modified field
  */
  const { handleSubmit, control, reset, formState: { dirtyFields } } = useForm({
    resolver: zodResolver(schema)
  })



  // Manage fetching user and/or user's groups.
  useEffect(() => {

    // Reset any error
    setError("");

    // If user exists, only fetch/set groups if they don't exist yet.
    if (user) {
      if (!groups) {
        getAndSetUserGroups(id, setGroups, setCurrentToast);
      }
    } else {
      // If there's no user object in state, the user has landed on the page
      // directly (not from the user mgmt table), so if there is no id from the
      // slug in the url, something is horribly wrong. Redirect back to the
      // user management page.
      if (!id) {
        console.error("Could not parse id from url.");
        navigateToUrl(`/${basePath}/${paths.USER_MGMT}`);
        return;
      }

      // If the user with this id already exists in state, use that object.
      const userFromState = userList.find((user: any) => user.id === id);
      if (userFromState) {
        setUser(userFromState);
        return;
      } else {
        // There is no user in state, so fetch one.
        fetchSingleUser(id, setUser, setError)
      }
    }
  }, [user]);

  // Added to handle async update of user, without this useEffect, useForm hook takes 
  // initial user load value of null and form doesn't show correct defaults
  useEffect(() => {
    resetForm(reset, user)
  }, [user])

  // Display error if there is one.
  const errorDisplay = () => {
    return (
      <div
        className="col error-display"
        data-testid="error-display"
      >
        <Typography variant="heading3">
          Error
        </Typography>
        <Typography variant="paragraph1">
          {error}
        </Typography>
      </div>
    );
  }

  // Props for updateUserDetails function
  const userDetailsProps = {
    setIsLoading: setIsLoading,
    user: user, dirtyFields: dirtyFields,
    getToast: getToast,
    setEditMode: setEditMode,
    setCurrentToast: setCurrentToast,
    reset: reset,
    id: id,
    setUser: setUser,
    setError: setError
  }

  // Return a fragment so that the footer is always rendered regardless of the
  // conditional rendering happening above it.
  return (
    <>
      <div className="user-details-wrap">
        {error && errorDisplay()}
        {((!user && !error) || isLoading) && <CircularProgress />}
        {user && !isLoading &&
          <>
            <UserDetailsHeadingRow
              user={user}
              editMode={editMode}
              setEditMode={setEditMode}
            />
            <UserDetailsBody
              user={user}
              groups={groups}
              editMode={editMode}
              control={control}
              basePath={basePath}
            />
          </>
        }
      </div>
      <UserDetailsFooter
        editedFields={dirtyFields}
        editMode={editMode}
        setEditMode={setEditMode}
        resetForm={() => resetForm(reset, user)}
        saveAndExit={handleSubmit((data) => updateUserDetails({ data: data, ...userDetailsProps }))}
        basePath={basePath}
      />
    </>
  );
};

export default UserDetailsPage;
