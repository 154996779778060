import { navigateToUrl } from "single-spa";
import { pagination, paths } from "../../shared/constants";
import { Group } from "../../shared/models/group";
/** Function to generate pagination object for Group Management Table return obj 
 * of type TableFooterPaginationProps
* @param currentPage currently visible
* @param rowsPerPage is max number of rows per page
* @param groups group object of type Group
* @param displayableRowCount which filters if any are selected
* @param setCurrentPage state updating function for current page state
* @param setRowsPerPage state updating function for rows per page state
 */
export const getTablePagination = ({
  currentPage,
  rowsPerPage,
  displayableRowCount,
  setCurrentPage,
  setRowsPerPage
}) => {
  return (
    {
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: pagination.ROWS_PER_PAGE_OPTIONS,
      totalResults: displayableRowCount,
      onNextPage: () => {
        const maxPageNum = Math.ceil(displayableRowCount / rowsPerPage) - 1;
        setCurrentPage(Math.min(currentPage + 1, maxPageNum));
      },
      onPreviousPage: () => setCurrentPage(Math.max(0, currentPage - 1)),
      onSelectRowsPerPage: (r) => {
        // When changing the number of results per page, dump the user back to the
        // first page.
        setCurrentPage(0);
        setRowsPerPage(r)
      }
    }
  )
}

/**
 * Function to navigate the 
 * 
 * @param basePath 
 * Path for settings microsolution, pulled from root.component props
 * 
 * @param group 
 * Group object of type Group
 */
export const navigateToGroupDetails = (
  basePath: string,
  group: Group
) => {
  navigateToUrl(`/${basePath}/${paths.GROUP_MGMT}/${group.group_id}`)
}

/**
 * Returns the group's name as a button.
 * 
 * @param group 
 * Group object for the group in this row.
 * 
 * @param basePath 
 * Path for settings microsolution, pulled from root.component props
 * 
 */
type ClickableGroupProps = {
  group: Group,
  basePath: string
}
export const ClickableGroupName = ({ group, basePath }: ClickableGroupProps) => (
  <button
    className="user-link-button"
    data-testid={`${group.group_id}`}
    onClick={() => navigateToGroupDetails(basePath, group)}
  >
    {group.group_display_name}
  </button>
)