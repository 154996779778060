import {
  userStatusSection,
  personalDataSection,
  groupsSection,
  deleteUserSection,
} from "./util";
import UserDetailsSection from "../UserDetailsSection/UserDetailsSection";
import { User } from "../../shared/models/user";
import { useModal, useToast } from "@aiops/styleguide";
import DeleteUserModal from "../DeleteUserModal";
import {  paths } from '../../shared/constants';
import { navigateToUrl } from "single-spa";

/**
 * Type for props of UserDetailsBody component.
 * 
 * @param user
 * The user object.
 * 
 * @param groups
 * The list of groups for the user.
 * 
 * @param editMode
 * Whether the user is in edit mode.
 * 
 * @param control
 * React hook form control object that manages form state
 *  *
 * @param basePath
 * Path for settings microsolution, pulled from root.component props
 */
export type UserDetailsBodyProps = {
  user: User,
  groups: Record<string, any>[],
  editMode: boolean,
  control: any,
  basePath: string
};

/**
 * Renders the body of the user details page, which consists of a list of
 * sections.
 */
const UserDetailsBody = ({
  user,
  groups,
  editMode,
  control,
  basePath
}: UserDetailsBodyProps) => {
  const { openModal } = useModal();
  const { setCurrentToast } = useToast();

  const confirmDeleteUsers = () => {
    openModal({
      children: <DeleteUserModal
        setToast={setCurrentToast}
        refreshTable={() => navigateToUrl(`/${basePath}/${paths.USER_MGMT}`)}
        userNameOrNames={[user.email]}
      />
    });
  }

  const sections = [
    userStatusSection(user, editMode, control),
    personalDataSection(user, editMode, control),
    groupsSection(groups, basePath),
    deleteUserSection({onClick: confirmDeleteUsers}),
  ].filter((s) => s);

  return (
    <div className="col user-details-body">
      {sections.map((s, idx, arr) => {
        return (
          <div key={idx} className="user-details-section-wrap">
            <UserDetailsSection
              title={s.title}
              titleRowRight={s.titleRowRight}
              content={s.content}
            />
            {idx !== arr.length - 1 && <div className="divider" />}
          </div>
        )
      })}
    </div >
  );
};

export default UserDetailsBody;
