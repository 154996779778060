import { Table } from "@aiops/styleguide";
import { useEffect, useState } from "react";
import { columns } from "./config";
import { pagination } from "../../shared/constants";
import { ClickableGroupName, getTablePagination } from "./utils";
import { Group } from "../../shared/models/group";
import { useSelector } from "react-redux";

// GroupManagementTable props
type GroupManagementTableProps = {
  tableIsLoading: boolean;
  groups: Group[];
  basePath: string;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
}

const GroupManagementTable = ({
  tableIsLoading,
  groups,
  basePath,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage
}: GroupManagementTableProps) => {

  const { totalGroupCount } = useSelector((state: {
    totalGroupCount: number,
  }) => state);

  const [tablePagination, setTablePagination] = useState(
    getTablePagination({
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      displayableRowCount: totalGroupCount,
      setCurrentPage: setCurrentPage,
      setRowsPerPage: setRowsPerPage
    })
  )

  useEffect(() => {
    const paginationObj = getTablePagination({
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      displayableRowCount: totalGroupCount,
      setCurrentPage: setCurrentPage,
      setRowsPerPage: setRowsPerPage
    })

    setTablePagination(paginationObj);
  }, [currentPage, rowsPerPage, totalGroupCount])


  const headerCells: Record<string, any> = {};

  // maps columns into table header cell object
  columns.forEach((col) => {
    const field = col.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: col.title,
      isCurrentSortColumn: false,
    }
  });

  return (
    <Table
      tableIsLoading={tableIsLoading}
      columnKeys={columns.map((col) => col.field)}
      headerCells={headerCells}
      rows={groups.map(group => (
        {
          ...group,
          group_display_name: <ClickableGroupName group={group} basePath={basePath} />
        }
      ))}
      pagination={tablePagination}
    />
  )
}

export default GroupManagementTable;