import * as types from "../types";

export const pendingUserCountReducer = (
    pendingUserCount = 0,
    action = { type: types.PENDING_USER_COUNT_SET, payload: 0 },
) => {
    switch (action.type) {
        case (types.PENDING_USER_COUNT_SET):
            return action.payload;
        default:
            return pendingUserCount;
    }
}

