import { User } from "../../shared/models/user";
import {
    ToastMsg,
    Typography,
    Checkbox,
    Button,
} from "@aiops/styleguide";

type UserWithError = User & {
    error?: string,
}

/**
 * Takes a list of users and a boolean and returns a ToastMsg communicating
 * how many users were added or not added to the group.
 * 
 * @param users 
 * List of User objects for users who were added or not added.
 * 
 * @param success 
 * True if users were added to the group successfully, false if not.
 * 
 * @param action
 * String that indicates what action was taken on the users (ie, "ADD" or "REMOVE").
 * 
 * @param openModal 
 * Function that opens a modal. Should be provided when success is false so that
 * the returned ToastMsg can include clickable functionality to open a modal and
 * view the errors. A valid ToastMsg will still be returned even if openModal
 * isn't passed, it'll just break the resultant Toast's functionality.
 * 
 * @param closeModal
 * Function that closes the modal. Should be provided when success is false so
 * that the View Errors modal can have a close button. If it's not provided,
 * the View Errors modal will not have a close button, but will still be
 * closeable by clicking outside of the modal.
 */
export const getToastFromUsers = (
    users: UserWithError[],
    success: boolean,
    action: "ADD" | "REMOVE",
    openModal?: (arg: any) => void,
    closeModal?: () => void,
): ToastMsg | null => {
    if (users.length === 0) {
        return null;
    }
    let paired = [];
    if (!success) {
        paired = users.map((u) => `${u.fullName}: ${u.error}`);
    }
    const userWord = users.length === 1 ? 'user' : 'users';
    const message = success
        ? `${users.length} ${userWord} successfully ${action === 'ADD' ? "added" : "removed"}.`
        : `${users.length} ${userWord} failed to ${action === 'ADD' ? 'add' : 'remove'}.`;
    const onViewErrors = () => (
        openModal
            ? openModal({
                headlineText: 'Errors',
                children: <>
                    {getNameErrorPairs(users)}
                    {closeModal && <Button onClick={closeModal} variant="filled">
                        Close
                    </Button>}
                </>
            })
            : console.error("openModal function not provided to getToastFromUsers")
    );
    return {
        severity: success ? 'success' : 'error',
        message,
        children: success ? undefined : getViewErrors(onViewErrors),
    }
}

/**
 * Returns the child of the ToastMsg that will be displayed when the users were
 * not added to the group successfully. This child is a clickable link that
 * opens a modal to display the errors.
 * 
 * @param onViewErrors 
 * Function that runs when the user clicks on the returned div (ie, function
 * that opens the modal to view the errors).
 * 
 */
export const getViewErrors = (onViewErrors: () => void) => (
    <Button
        className="view-errors-btn"
        data-testid="view-errors"
        onClick={onViewErrors}
        disableRipple
    >
        View Errors
    </Button>
)

/**
 * Takes a list of User objects that include an error field and returns a list
 * of divs that display the user's name and error message.
 * 
 * @param users 
 * List of User objects that include an error field.
 */
export const getNameErrorPairs = (users: UserWithError[]) => (
    users.map((u) => {
        const typographyStyle = {
            width: '100%',
            textAlign: 'left',
        }
        return (
            <div
                style={{
                    marginBottom: '20px',
                    width: '100%',
                }}
            >
                <Typography
                    variant="paragraph1-bold"
                    // @ts-ignore
                    style={typographyStyle}
                >
                    {`Add user ${u.fullName || "[Unknown User]"} failed.`}
                </Typography>
                <Typography
                    variant="paragraph1"
                    // @ts-ignore
                    style={typographyStyle}
                >
                    {u.error || "Unknown error."}
                </Typography>
            </div>
        )
    })
);

/**
 * Returns a row of checkboxes and labels that are used to select users in the
 * select users dropdown.
 * 
 * @param selected 
 * True if the user is selected, false if not.
 * 
 * @param label 
 * The label to display next to the checkbox (ie user's name/name & email).
 * 
 */
export const renderAutocompleteOption = (selected: boolean, label: string) => {
    return (
        <div className='row'>
            <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
            />
            <Typography variant="caption1c">
                {label}
            </Typography>
        </div>
    );
};

/**
 * Function that takes a list of object with a label property (in this use case
 * those objects are users) and returns a Typography component that displays
 * all labels connect by commas.
 * 
 * The return value of this function (list of labels in Typography) is the
 * display of the list of selected users that the user has made.
 * 
 * @param list 
 * List of objects with a label property (ie users).
 * 
 */
export const renderTag = (list: any) => (
    <Typography
        id="selected-users-tag"
        variant="caption1"
    >
        {list.map((u: { label: string }) => u.label).filter((str) => str).join(', ')}
    </Typography>
);
