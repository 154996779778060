import { useState } from 'react';
import "./RemoveUserFromGroupModal.scss";
import {
  confirmationModalButtonRow,
  Typography,
} from "@aiops/styleguide";
import { User } from "../../shared/models/user";
import { removeUsersFromGroups } from "../../shared/apiHelper";
import { getToastFromUsers } from '../AddUserToGroupModal/utils';

/**
 * Type for props of RemoveUserFromGroupModal component.
 * 
 * @param user
 * User to be removed from group.
 * 
 * @param groupId
 * Id of group to remove user from.
 * 
 * @param closeModal
 * Function to close modal.
 * 
 * @param blockCloseModal
 * Function to block/unblock modal from being closed.
 * 
 * @param openModal
 * Function to open modal.
 * 
 * @param onRemoveUser
 * Function to call after user is successfully removed from group.
 * 
 * @param setToast
 * Function to set toast message.
 * 
 */
export type RemoveUserFromGroupModalProps = {
  users: User[],
  groupId: string,
  closeModal: () => void,
  blockCloseModal: (arg: boolean) => void,
  openModal: (arg: any) => void,
  refreshTable: () => void,
  setToast: (arg: any) => void,
};

/**
 *  Modal to confirm removal of user from group.
 */
const RemoveUserFromGroupModal = ({
  users,
  groupId,
  openModal,
  closeModal,
  blockCloseModal,
  refreshTable,
  setToast
}: RemoveUserFromGroupModalProps) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const count = users.length;
  const userString = count === 1 ? "user" : "users";

  const onConfirmRemoval = async () => {
    try {
      if (users.filter((u) => u.id).length < 1) {
        throw new Error(`User list contains no users with ids: ${JSON.stringify(users)}`);
      }

      // While removing user, block ability to close modal
      blockCloseModal(true);
      setIsRemoving(true);
      await removeUsersFromGroups(users, [groupId]);

      refreshTable();
      const { successUsers, errorUsers } = await removeUsersFromGroups(users, [groupId]);
      const successToast = getToastFromUsers(successUsers, true, 'REMOVE');
      const errorToast = getToastFromUsers(errorUsers, false, 'REMOVE', openModal, closeModal);
      // These toasts will either be valid toast messages (if the arrays passed
      // to getToastFromUsers are non-empty) or null (for empty arrays, ie if 
      // no successes/no errors), so filter to get only truthy toast messages.
      // It should never be the case that both are null (if so there's a problem
      // with assignUsersToGroups and/or the endpoint) but in this case the modal
      // will close without showing a toast.A
      setToast([successToast, errorToast].filter((t) => t));
    } catch (e) {
      console.error(`Error removing users from group ${groupId}: `, users, e);
      setToast({
        severity: "error",
        message: `Error: ${e.message || `Unable to remove ${count} ${userString} from group.`}}`,
      });
    } finally {
      // Unset these states regardless of success or failure, and close modal
      blockCloseModal(false);
      setIsRemoving(false);
      closeModal();
    }
  }

  return (
    <div className="removeUserModal">
      <Typography
        variant="heading3-light"
        textAlign={"center"}
        sx={{
          marginBottom: `${isRemoving ? '20px' : '0px'}`,
        }}
      >
        {`Are you sure you want to remove ${count === 1 ? `this ${userString}` : `${count} ${userString}`} from the group?`}
      </Typography>
      {confirmationModalButtonRow("Cancel", closeModal, "Remove", onConfirmRemoval, isRemoving)}
    </div>
  );
};

export default RemoveUserFromGroupModal;
