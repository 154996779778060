import { useState } from 'react';
import { useForm } from 'react-hook-form';
import "./CreateUserModal.scss";
import {
  useModal,
  confirmationModalButtonRow,
} from "@aiops/styleguide";
import { createUser } from '../../shared/apiHelper';
import {
  successToast,
  errorToast,
  getFormTextField,
  dataIsValid,
} from './util'

// Interface for this three-field form
export interface ICreateUser {
  givenName: string,
  familyName: string,
  email: string,
}

/**
 * Type for props of CreateUserModal component.
 * 
 * @param setToast
 * Function that takes a ToastMsgObject as input and opens a toast. Equivalent 
 * to using the setCurrentToast field of the useToast hook, but the useToast
 * hook can't be used in this component because it's a modal that exists outside
 * of the ToastProvider context. So the useToast hook should be called from a
 * component within that context, and the setCurrentToast function destructured
 * and passed as a prop here.
 */
export type CreateUserModalProps = {
  setToast: (arg: any) => void,
};

/**
 * Modal that allows user to create a new user.
 */
const CreateUserModal = ({ setToast }: CreateUserModalProps) => {
  // True while waiting for create user api request to resolve
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Function that closes the modal
  const { closeModal, blockCloseModal } = useModal();

  // Get the handleSubmit and control functions from react-hook-form
  const { handleSubmit, control } = useForm<ICreateUser>({
    defaultValues: {
      givenName: "",
      familyName: "",
      email: "",
    }
  });

  /**
   * Submits the form: checks that data is valid, awaits the result of the api
   * request, and closes the modal, opening a success or error toast depending
   * on the result of the request and whether the data is valid. (The modal will
   * always be closed and a success or failure toast will always be opened.)
   * 
   * @param formData 
   * The data that the user has entered into the form (first name, last name,
   * email address).
   */
  const submitForm = async (formData: ICreateUser) => {
    setIsLoading(true);
    blockCloseModal(true);

    // Try/catch block opens error toast for all errors in this process.
    try {
      if (!dataIsValid(formData)) {
        throw new Error("Invalid or incomplete submission.");
      }
      const res = await createUser({
        givenName: formData.givenName,
        familyName: formData.familyName,
        userName: formData.email,
      });
      setToast(res.error
        ? errorToast(res.error?.message || res.error)
        : successToast()
      );
    } catch (err) {
      setToast(errorToast(err.message || err));
    }
    setIsLoading(false);
    closeModal();
    blockCloseModal(false);
  }

  return (
    <form className='createUserModal'>
      {
        [
          { controlLabel: 'givenName', placeholder: 'Given Name' },
          { controlLabel: 'familyName', placeholder: 'Family Name' },
          { controlLabel: 'email', placeholder: 'Email' },
        ].map((obj, idx) => getFormTextField(control, obj.controlLabel, obj.placeholder, idx))
      }
      {confirmationModalButtonRow("Cancel", closeModal, "Create", handleSubmit(submitForm), isLoading)}
    </form>
  );
};

export default CreateUserModal;
