//Mapping of API group field and display name
export const columns = [
  {
    field: 'group_display_name',
    title: 'Group Name',
  },
  {
    field: 'number_of_users',
    title: 'No. of Users',
  },
];