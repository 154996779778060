import { getAllGroups, getAllUsersInGroup } from "../../shared/apiHelper";
import { Group } from "../../shared/models/group";
import { User } from "../../shared/models/user";
import { setGroupList } from "../../shared/store/actions/groupListActions";
import { setTotalGroupCount } from "../../shared/store/actions/totalGroupCountActions";


/** Function to update the displayed 
 * groupName to correspond to the currently selected group
 * 
 * @param id 
 * Id of currently selected group
 * @param setSelectedGroupName 
* Selected groupName updating function
 * @param allGroups 
 * List of all groups of type Group
 */

export const updateGroupName = async (
  id: string,
  setSelectedGroupName: (name: string) => void,
  allGroups: Group[]
) => {
  const selectedGroup = allGroups.find(group => group?.group_id.toString() === id);
  setSelectedGroupName(selectedGroup?.group_display_name || "")
}


/** Function to fetch a groups users
 * 
 * @param groupList 
 * List of all groups of type Group
 * 
 * @param idToken 
* Currently logged in user's id token.
 * 
 * @param id 
 * Id of currently selected group
 * 
 * @param dispatch 
 * Redux store state updating function
 * 
 * @param setSelectedGroupName 
 * Selected groupName updating function
 * 
 * @param setUsersInGroup 
 * Group's users's state updating function
 * 
 * @param setTableIsLoading 
 * Table loading state updating function
 * 
 * @param setDisplayableRowCount 
 * State updating function for total user count
 */
export const fetchGroupUsers = async (
  groupList: Group[],
  id: string,
  dispatch: any,
  setSelectedGroupName: (name: string) => void,
  setUsersInGroup: (users: User[]) => void,
  setTableIsLoading: (isLoading: boolean) => void,
  setDisplayableRowCount: (count: number) => void,
  rowsPerPage: number,
  offset: number
) => {
  if (groupList.length === 0) {
    try {
      const { groups, totalCount }: { groups: Group[], totalCount: number } = await getAllGroups();
      dispatch(setGroupList(groups));
      dispatch(setTotalGroupCount(totalCount));
      updateGroupName(id, setSelectedGroupName, groups);
    }
    catch (err) {
      dispatch(setGroupList([]));
      setTableIsLoading(false);
      console.error("error", err)
      return;
    }
  }
  try {
    const { users, totalUserCount } = await getAllUsersInGroup(id, rowsPerPage, offset);
    setUsersInGroup(users);
    setDisplayableRowCount(totalUserCount);
  } catch (err) {
    setTableIsLoading(false);
    console.error(err)
  }
  setTableIsLoading(false);
}