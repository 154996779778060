import * as types from "../types";

export const totalUserCountReducer = (
    totalUserCount = 0,
    action = { type: types.TOTAL_USER_COUNT_SET, payload: 0 },
) => {
    switch (action.type) {
        case (types.TOTAL_USER_COUNT_SET):
            return action.payload;
        default:
            return totalUserCount;
    }
}
