import { userStates } from "../../shared/constants";

export const columns = [
    {
        field: 'fullName',
        title: 'Full Name',
    },
    {
        field: 'email',
        title: 'Email',
    },
    {
        field: 'statusString',
        title: 'Status',
    },
    {
        field: 'actions',
        title: 'Actions',
    },
];

export const filters = [
    {
        id: userStates.PENDING,
        text: "Pending",
        selected: false,
        disabled: false,
    },
    {
        id: userStates.ACTIVE,
        text: "Active",
        selected: false,
        disabled: false,
    },
    {
        id: userStates.INACTIVE,
        text: "Deactivated",
        selected: false,
        disabled: false,
    },
];
