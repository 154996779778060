import { useState } from "react";
import "./DeleteUserModal.scss";
import {
  useModal,
  confirmationModalButtonRow,
  Typography,
  ToastMsg,
} from "@aiops/styleguide";
import { deleteUsers } from "../../shared/apiHelper";

/**
 * Type for props of DeleteUserModal component.
 * 
 * @param setToast
 * Function that sets a Toast message in the ToastProvider context.
 * 
 * @param refreshTable
 * Function that resets the table and will run when modal closes after
 * successfully deleting user(s) (but will NOT run if modal closes after 
 * deletion fails).
 * 
 * @param userIdOrIds
 * Either one user ID or an array of user IDs, identify user(s) to delete.
 */
export type DeleteUserModalProps = {
  setToast: (arg: ToastMsg) => void,
  refreshTable: () => void,
  userNameOrNames: string | string[],
};

/**
 * The contents of the modal that warns the user that deleting one user or
 * multiple users is irreversible.
 */
const DeleteUserModal = ({ setToast, refreshTable, userNameOrNames }: DeleteUserModalProps) => {
  const userNameArray: string[] = Array.isArray(userNameOrNames)
    ? userNameOrNames
    : [userNameOrNames];
  const plural = userNameArray.length > 1;
  const headlineText = plural
    ? `Are you sure you want to delete ${userNameArray.length} users?`
    : 'Are you sure you want to delete this user?';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { closeModal, blockCloseModal } = useModal();
  // Toast to display if create user api call is successful
  const successToast = {
    severity: 'success' as const,
    message: `${plural ? `${userNameArray.length} users were deleted.` : "User was deleted."}`,
  }

  // Returns a Toast to display when the create user api call fails
  // Checks whether message is actually a string so that you can pass it what
  // you think will be a string (like error.message) and it will fail gracefully
  // if you're wrong.
  const errorToast = (message: string = "") => ({
    severity: 'error' as const,
    message: (message && typeof message === 'string')
      ? message
      : `Unable to delete ${plural ? "users" : "user"}.`,
  })

  // Blocks the user from closing the modal, sets the loading state to true,
  // makes the api call to delete user(s), awaits its resolution, catches any
  // error, sets success or error toast, closes modal.
  const irrevocablyDeleteUsers = async (ids: string[]) => {
    blockCloseModal(true);
    setIsLoading(true);
    try {
      const res = await deleteUsers(ids);
      refreshTable();
      setToast(successToast);
    } catch (err) {
      console.error("ERROR: ", err);
      setToast(errorToast(err.message || err));
    }
    setIsLoading(false);
    closeModal();
  }

  return (
    <div className="col delete-user-modal">
      <Typography variant="heading3-light" sx={{ textAlign: 'center' }}>
        {headlineText}
      </Typography>
      <Typography variant="heading3-light" sx={{ textAlign: 'center' }}>
        This action cannot be undone.
      </Typography>
      {confirmationModalButtonRow(
        "Cancel",
        closeModal,
        "Delete User",
        () => irrevocablyDeleteUsers(userNameArray), isLoading)
      }
    </div>
  );
};

export default DeleteUserModal;
