import { getAllGroups } from "../../shared/apiHelper";
import { setGroupList } from "../../shared/store/actions/groupListActions";
import { setTotalGroupCount } from "../../shared/store/actions/totalGroupCountActions";

// Function to call getAllGroups api and set them in local state
/**
 * 
 * @param setGroups 
 * @param setTableIsLoading 
 * @param dispatch 
 * @param setDisplayableRowCount 
 * @param rowsPerPage
 * @param currentPage
 */
export const getAndSetGroups = async (
  setGroups,
  setTableIsLoading,
  dispatch,
  rowsPerPage,
  offset
) => {
  const { groups: allGroups, totalCount: groupCount } = await getAllGroups(rowsPerPage, offset);
  setGroups(allGroups);
  dispatch(setGroupList(allGroups));
  dispatch(setTotalGroupCount(groupCount));
  setTableIsLoading(false);
}