import * as types from "../types";

export const userListReducer = (
    userList = [],
    action = { type: types.USER_LIST_SET, payload: [] },
) => {
    switch (action.type) {
        case (types.USER_LIST_SET):
            return action.payload;
        default:
            return userList;
    }
}
